/**
 * Adaptive Placeholders
 */

(function($) {
  'use strict';

  Drupal.behaviors.adaptivePlaceholders = {
    attached: false,

    labelMode: function($input) {
      $input.addClass('adpl__mode-label');
      $input.removeClass('adpl__mode-placeholder');
    },

    placeholderMode: function($input) {
      $input.removeClass('adpl__mode-label');
      $input.addClass('adpl__mode-placeholder');
    },

    toggleMode: function($input) {
      var self = this;

      if ($input.val() === '') {
        self.placeholderMode($input);
      } else {
        self.labelMode($input);
      }
    },

    bindEvents: function($input) {
      var self = this;

      // swap out placeholder/label classes on focus in or out
      $input.on('focusin', function() {
        self.labelMode($input);
      });

      $input.on('focusout', function() {
        self.toggleMode($input);
      });

      $input.on('change', function() {
        self.toggleMode($input);
      });
    },

    setupDOM: function($inputs) {
      var self = this;

      $inputs.each(function() {
        var $input = $(this);
        if (!$input.hasClass('adpl--processed')) {
          var $label = $input.siblings('label');
          var placeholder = $input.attr('placeholder') || $label.attr('placeholder');

          // input needs a placeholder
          if (!placeholder) {
            return true;
          }

          // if label exists
          if ($label.length > 0) {
            $label.remove(); // hold in space
          } else {
            // if label does not exist, build it
            var id = $input.attr('id');
            if (!!id) {
              $label = $('<label class="label" for="' + id + '">' + placeholder + '</label>');
            } else {
              // if there is no label, and no id on the input, then we cannot proceed
              return true;
            }
          }

          // ensure that label contains attributes required for display
          if (!$label[0].hasAttribute('placeholder')) {
            $label.attr('placeholder', placeholder);
          }

          // ensure that label contains attributes required for display
          if (!$label[0].hasAttribute('alt')) {
            $label.attr('alt', placeholder);
          }

          // ensure that label contains an inner span.label-content wrapping the text
          if ($label.find('span.label-content').length < 1) {
            $label.wrapInner('<span class="label-content"></span>');
          }

          // position the label after the input, required for proper z-index
          $label.insertAfter($input);

          // cleanup inputs
          if ($input.attr('id') == 'google_autocomplete') {
            // google_autcomplete can't have its placeholder attribute removed without
            // it reverting to show the default, so use empty string instead
            $input.attr('placeholder','');
          } else {
            $input.removeAttr('placeholder');
          }

          // set states, bind events
          self.placeholderMode($input);
          self.bindEvents($input);
          self.toggleMode($input);

          // add CSS class for styling
          $input.addClass('adpl--processed');
        }
      });
    },

    attach: function(context, settings) {
      var self = this;

      if ($('html').hasClass('no-placeholder')) {
        return;
      }

      var $inputs = $('input[type="text"], input[type="email"], input[type="tel"], input[type="number"], input[type="password"], textarea', context).not('.no-adpl');
      self.setupDOM($inputs);
    }
  };
})(jQuery);

/**
 * Clickable blocks - entire block will be clickable
 * Styles are in _helpers.scss under .block--linked
 *
 * Usage:
 * {{#link}} data-clickable="{{link_path}}"{{/link}}
 * {{#url}} data-clickable="{{href}}"{{/url}}
 */


(function($){

  Drupal.behaviors.clickable = {
    attach: function(context, settings) {
      $('[data-clickable!=""][data-clickable]', context).once('clickable').addClass('block--linked').on('click', function() {
        if (window.location.hash) {
          history.replaceState('', '', $(this).data('clickable')); // fix for hitchhiking hashes
        }
        window.location.href = $(this).data('clickable');
      });
    }
  };

})(jQuery);

/**
 * @namespace
 */
var site = site || {};
site.signin = site.signin || {};
var generic = generic || {};

(function($, site) {
  Drupal.behaviors.forgot_password = {
  attach: function(context) {

  /**
   * One-time call to collect specific RB Keys used for forget password.
   * @methodOf site.signin
   */

  site.signin.getRBKeys = function() {
    site.signin.rb = generic.rb("error_messages");
    site.signin.forgotPasswordEmailNotFound   = site.signin.rb.get('incorrect_pwremind');
    site.signin.forgotPasswordNoEmailProvided = site.signin.rb.get('session_pw_hint');
    site.signin.forgotPasswordMigratedUser    = site.signin.rb.get('migrated.mobile_account.signin');
  }

  /**
    * This method is used to set up the forget password functionality
    * on the site.
    * Takes the passed element in the DOM and gets the required form
    * nodes and places them within forgotPassArgs object.
    * site.signin.setForgetPassword() is then called if the param resetPassword
    * is set to true.
    * @param {Object} args
    * @param {Object} args.emailNode **REQUIRED** DOM element of either a
    * form element or wrapper element of the email.
    * @param {Object} args.errorListNode **REQUIRED** DOM element used to show
    * password hint or error messaging if hint is not available.
    * @param {Object} args.forgotPasswordLink **REQUIRED** DOM element of the
    * forget password link.
    * @params {element} forget link node set on dom:load
    * @methodOf site.signin
  */
  site.signin.forgotPassword = function(args) {
    if ((args.emailNode.length > 1) || (!args.forgotPasswordLink) || (!args.errorListNode) ) {
      return null;
    }

    site.signin.getRBKeys();

    var errorListNode = args.errorListNode;
    var emailNode = args.emailNode;
    var forgotPasswordLink = args.forgotPasswordLink;
    var forgotPasswordNote = args.forgotPasswordNote;
    var showInlineErrors = args.showInlineErrors;
    var forgotPasswordCopy = $("#lpw-text");
    // content may have been set on server side. If so, do not hide.
    if (forgotPasswordCopy.length > 1 && forgotPasswordCopy.html().length<1) {
        forgotPasswordCopy.hide();
    }

    forgotPasswordLink.on('click', function(evt) {
      evt.preventDefault();

      if(!!showInlineErrors) {
        $('input.error').each(function() {
          var $self = $(this);
          $self.siblings('.field-error-msg').empty().hide();
          $self.removeClass('error');
        });
      }

      forgotPasswordCopy.show();
      var email = site.signin.getEmailAddress(emailNode);

      if (site.signin.forgotPasswordNoEmailProvided === 'session_pw_hint' || typeof site.signin.forgotPasswordNoEmailProvided === 'undefined') {
        site.signin.forgotPasswordNoEmailProvided = typeof site.translations.product.no_email_error !== 'undefined' ? site.translations.product.no_email_error : '';
      }

      if (email.length < 1) {
        $('.error_messages').empty();
        if (!!showInlineErrors) {
          emailNode.siblings('.field-error-msg').html(site.signin.forgotPasswordNoEmailProvided).show();
          emailNode.addClass('error');
        } else {
          $('.error_messages').removeClass('multiple-error-message').addClass('single-error-message').append('<li class="s" id="signin_error.email_address.">' + site.signin.forgotPasswordNoEmailProvided + '</li>');
        }

        generic.showErrorState(emailNode[0]);

        // remove errors from new user section div
        $('#new-account > .error_messages').empty();

        return null;
      }

      site.signin.requestPassword(email);

      // remove errors from new user section div
      $('#new-account > .error_messages').empty();
      return false;
    });
  };

  /**
    * This method is used to reset a users password by submitting a hidden form.
    * @param {email} the user's email address **REQUIRED**
    * @param {actionURL} the page URL of the reset page **REQUIRED**
    * **NOTE**: The error check for if an account exists is handled by the password
    * hint function. The reset is hidden inside the password hint function
    * so no duplicate error checking is needed here.
  */
  site.signin.initResetPassword = function(emailNode) {
    //have to initialise the link here because it isn't on the page until the pw hint method is invoked
    var email = site.signin.getEmailAddress(emailNode);
    var resetPassLink = $('#pwd-reset');
    if (resetPassLink) {
      resetPassLink.on('click', function(evt) {
        evt.preventDefault();
        site.signin.requestPassword(email);
      });
    }};


  /**
    * This method is used to direct the user to registration.tmpl or password_request.tmpl.
    * The passed values are injected into the genric form before it is submitted.
    * @param {email} the user email that will be passed. **REQUIRED**
    * @param {actionURL} action url used on user submit. **REQUIRED**
    * @param {returnURL} passed when an action is needed after the user
    * has gone to the next template page. **NOT REQUIRED**
    * Example case for returnURL is if the user goes through checkout and registers,
    * the returnURL is used to pass the viewbag action url to the registration page. Once
    * registration form is filled out, user will be brought to viewbag.
    * @methodOf site.signin
  */
  site.signin.submitHiddenSigninForm = function(args) {
    if (!args.actionURL || !site.signin.hiddenForm) {
      return null;
    }
    site.signin.hiddenForm.attr('action', args.actionURL);
    var hiddenEmailNode = $('.js-hidden-email');
    hiddenEmailNode.val(args.email);

    if (args.returnURL) {
      var hiddenReturnNode = $('.js-hidden-return');
      hiddenReturnNode.val(args.returnURL);
    }
    site.signin.hiddenForm.submit();
  };

  /**
    * This method is used to call site.signin.submitHiddenSigninForm by
    * passing the user's email used in the reset form submit.
    * @param {String} the user email that will be passed. **REQUIRED**
    * @methodOf site.signin
    */
      site.signin.requestPassword = function(emailAddr) {
        site.signin.hiddenForm = $('.js-signin-hidden-form');
        var actionPath = '/account/password_request.tmpl';
        if (Drupal.settings.common.is_hub_site) {
          actionPath = '/' + generic.cookie('LOCALE').toLowerCase().split('_').reverse().join('/') + '/account/password_request.tmpl';
        }
        if (site.signin.hiddenForm) {
          site.signin.submitHiddenSigninForm({
            email: emailAddr,
            actionURL: actionPath
          });
        }
      };
  /**
    * This method is used to pull the user's email from either a form
    * input or container html tag wrapper (i.e. div, span, etc)
    * @param {String} emailNode the user email that will be passed. **REQUIRED**
    * @methodOf site.signin
  */
  site.signin.getEmailAddress = function(emailNode) {
    if(!emailNode ) return null;
    return emailNode.val();
  }

  }
  };

})(jQuery, site);

/* global lazySizes */
Drupal.behaviors.gnav = (function($) {
  'use strict';

  var bps = Unison.fetch.all();
  var bp = Unison.fetch.now();
  var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
  var $items = $();

  // Detect transition end event: http://stackoverflow.com/a/25937118
  var transEndEvent = (function() {
      var el = document.createElement('div');

      var transEndEventNames = {
        WebkitTransition : 'webkitTransitionEnd',
        MozTransition    : 'transitionend',
        OTransition      : 'oTransitionEnd otransitionend',
        transition       : 'transitionend'
      };

      for (var name in transEndEventNames) {
        if (el.style[name] !== undefined) {
          return transEndEventNames[name];
        }
      }

      return false;
    })();

  function _splitItems($originalList, itemClass, maxItems) {
    var $listContainer = $originalList.clone().empty();
    var $lists = $('<div />');
    var i = 0;
    var j = 0;

    var $list = $listContainer.clone();

    $('.' + itemClass, $originalList).each(function() {
      if (j >= maxItems) {
        i++;
        j = 0;
        $lists.append($list);
        $list = $listContainer.clone();
      }

      $list.append(this);
      j++;
    });

    $lists.append($list);
    $lists.children().addClass('list-split list-split--' + (i + 1));

    $originalList.after($lists.html()).remove();
  }


  var behavior = {
    attached: false,
    attach: function(context) {
      var self = this;
      var expanded = false;
      var to;

      var transitionTime = 250;
      var $header = $('.site-header', context);
      var $metaNav = $('.site-header__main', $header);
      var $mainNav = $('.site-header__section--nav', $header);
      var $utilityNav = $('.site-header__section--utilities', $metaNav);
      var $utilityNavContent = $('.js-gnav-util__content', $metaNav);
      var $utilityNavTrigger = $('.js-gnav-util-trigger', $metaNav);
      var $primaryNavMenus = $('.menu__list', $mainNav);
      var $mainNavDropdownTriggers = $('.menu__link--has-children', $mainNav);
      var $mobileMainNavLabel = $('.menu__label', $mainNav);
      var $primaryItems = $primaryNavMenus.first().children().has('.menu');
      var $primaryItemLinks = $primaryItems.children('.menu__link');
      var $productCategories = $('.menu-ref__title', $primaryItems);
      var $firstPrimaryDropdown = $('.menu--lvl-2', $mainNav).first();
      var $gnavDropdownContent = $('.gnav-dropdown__content', context);
      var $body = $('body', context);
      var $activeItem = $();
      var signedIn = (site.userInfoCookie.getValue('signed_in') - 0 === 1);
      var is_loyalty_member = (site.userInfoCookie.getValue('is_loyalty_member') - 0 === 1);

      $('.js-nav--primary', $header).on('clickoutside', function() {
        // if(isDesktop) {
          behavior.close();
        // }
      });

      // Register utility nav items:
      $items = $items.add($('.gnav-util', $header));
      $items = $items.add($('.menu__item', $utilityNav));

      // collapse the menu for cases like anchors
      $('.menu__link', $gnavDropdownContent).on('click', collapseMenu);


      //Add a global class to close gnav
      // $('.js-close-nav').on('click', function(e) {
      //   e.preventDefault();
      //   $body.removeClass('mobile-gnav-active');
      //   self.close();
      // });

      // CSS doesnt always hide since were injecting inline display styles so let's just remove this element when logged in
      if (signedIn) {
        if (!is_loyalty_member) {
          $("#gnav_my_smashcash").closest("li").addClass("hidden");
        }
        $('.menu__item--signed-out').remove();
      } else {
        $('.menu__item--signed-in').remove();
      }

      // Divide menu-ref menus into lists with no more than 11 items.
      $('.gnav-dropdown .menuref__content .menu__list', $header).each(function() {
        _splitItems($(this), 'menu__item', 11);
      });

      // Divide By Collection/Benefit lists into groups of no greater than 4 items.
      $('.gnav-by-collection__items', $header).each(function() {
        _splitItems($(this), 'gnav-by-collection__item', 4);
      });

      // Mobile gnav is click-to-expand. While the display logic is
      // css/class-based, the animation is jQuery. This function is used to
      // toggle the display and update the class/styles appropriately:
      function slideToggle($item, $el, className) {
        var show = !$el.is(':visible');

        if (show) {
          $item.toggleClass(className);
          // Right here is a good place to trigger resize so things like hidden
          // carousels can get laid out properly now that they're visible:
          $(window).trigger('resize');
          $el.toggle();
        }
        $el.slideToggle(transitionTime, function() {
          $(this).css({ display: '', height: '', overflow: '' });
          if (!show) {
            $item.toggleClass(className);
          }
        });
      }

      // Mobile nav interaction
      $mainNavDropdownTriggers.on('click', function(event) {
        // This only applies to the view below medium:
        if (isDesktop) {
          return;
        }
        var $this = $(this);
        // Give this item active
        var $item = $this.closest('.js-menu-item').toggleClass('active js-active-menu');
        // Hide the other items that dont have active
        var $otherItems = $item.parent().children('.js-menu-item').not('.js-active-menu');
        var menuOpen = $item.hasClass('js-active-menu');
        var $subMenu = $item.find('.js-submenu');
        var $parentMenus = $('.js-active-menu', $mainNav).children('.js-menu-has-children');
        // Slide the others based on whether the mobile nav is open & active
        menuOpen ? $otherItems.slideUp(transitionTime) : $otherItems.slideDown(transitionTime);

        slideToggle($item, $('.menu', $item).first(), 'menu__item--selected');
        // Handles nested menus
        $subMenu.add($parentMenus).addClass('hidden');
        if ($item.hasClass('js-active-menu')) {
          $this.siblings('.js-submenu').removeClass('hidden');
          $this.removeClass('hidden');
          $item.removeAttr('style');
        } else {
          $item.closest('.js-active-menu').children('.js-menu-has-children').removeClass('hidden');
        }
        event.preventDefault();
      });

      // Desktop logic for Primary Menu:

      function expandActiveItem() {
        collapseItems();
        $activeItem.addClass('menu__item--expanded active js-active-menu');

        if (!isDesktop) {
          $mobileMainNavLabel.stop(true,true).fadeIn(transitionTime);
        }

        // Gnav dropdown with carousel gallery are designed to have a black (inverted) theme
        if ($activeItem.hasClass('menu__item--gallery')) {
          $mainNav.addClass('site-header__section--inverted');
        }

        // fix for lazy slick images
        $activeItem.find('img.lazyload').each(function(i, img) {
          lazySizes.loader.unveil(img);
        });

        if (!isDesktop) {
          $('.menu__link--has-children').removeClass('menu__link--product-parent');
        }
      }

      function collapseItems() {
        $mobileMainNavLabel.stop(true,true).hide(transitionTime);
        $productCategories.trigger('close');
        $primaryItems.removeClass('menu__item--expanded active js-active-menu');
        if (!isDesktop && $body.hasClass('gnav-active')) {
          $('.menu__item', $primaryItems).attr('style', '');
        }
        if ($mainNav.hasClass('site-header__section--inverted')) {
          $mainNav.removeClass('site-header__section--inverted');
        }
      }

      function expandMenu() {
        expanded = true;
        $body.addClass('gnav-active');
        expandActiveItem();
      }

      function collapseMenu() {
        expanded = false;
        // Once other events have triggered, check if the menu link was clicked
        // If it was, we want to close the menu and remove the black theme
        setTimeout(function() {
          if(!$('.js-nav--utility').find('.gnav-util__link.active, .js-gnav-util-trigger.active').length) {
            $body.removeClass('gnav-active');
          }
          else if(($('.js-nav--utility').find('.gnav-util__link.active, .js-gnav-util.active').length) && (!isDesktop)) {
            $('.js-gnav-util .js-gnav-util-close').trigger('click');
          }
        }, 100);
        $activeItem = $();

        if (!transEndEvent) {
          collapseItems();
        }

        if ($mainNav.hasClass('site-header__section--inverted')) {
          $mainNav.removeClass('site-header__section--inverted');
        }

        if (!isDesktop) {
          $('.menu__link--has-children').addClass('menu__link--product-parent');
        }
      }

      $mobileMainNavLabel.on('click', function(event) {
        if (isDesktop) {
          return;
        }
        // If this is being animated stop it
        $(this).stop(true, true);
        event.preventDefault();
        collapseItems();
        collapseMenu();
        // @TODO -- Wrap this into some sort of function to reset the nav
        $('.menu__item', $mainNav).removeClass('menu__item--selected menu__item--expanded active js-active-menu');
        $('.menu-ref', $mainNav).removeClass('active js-active-menu');
        $('.menu__link', $mainNav).removeClass('hidden');
        $('.menu__list--lvl-1 > .menu__item').slideDown(transitionTime);
      });

      // Touch devices use click event for primary nav:
      $primaryItemLinks.on('click', function(event) {
        if (isDesktop) { return; }
        event.preventDefault();

        if ($(this).closest('.menu__item--expanded').length) {
          collapseItems();
          collapseMenu();
        } else {
          $activeItem = $(this).parent();
          if (expanded) {
            expandActiveItem();
          } else {
            expandMenu();
          }
        }
        $('.js-gnav-util__content', $utilityNav).addClass('hidden');

      });

      $productCategories.on('click', function(event) {
        if (isDesktop) {
          return;
        }
        event.preventDefault();

        var $thisMenuRef = $(this).parent();
        if ($thisMenuRef.hasClass('active')) {
          $(this).trigger('close');
        } else {
          $(this).closest('.menu__item--expanded').children('.menu__link--has-children').slideUp(transitionTime);
          $thisMenuRef.addClass('active js-active-menu').find('.menu-ref__content').slideDown(transitionTime);
          $(this).closest('.gnav-dropdown__content').find('.menu-ref').not($thisMenuRef).hide();
        }
      });

      $productCategories.on('close', function() {
        if (isDesktop) {
          return;
        }
        var $thisMenuRef = $(this).parent();
        $thisMenuRef.removeClass('active js-active-menu').find('.menu-ref__content').slideUp(transitionTime);
        $(this).closest('.gnav-dropdown__content').find('.menu-ref').show();
        $(this).closest('.menu__item--expanded').children('.menu__link--has-children').slideDown(transitionTime);
      });

      // Non-touch devices use mouseenter/leave:
      // On mouseenter, ALL of the children are faded in, but only the active one
      // is positioned into view:
      $primaryItemLinks.on('mouseenter', function() {
        if (!isDesktop) { return; }
        clearTimeout(to);

        $activeItem = $(this).parent();
        if (expanded) {
          expandActiveItem();
        } else {
          // Delay the opening of the Gnav menu.
          to = setTimeout(expandMenu, 200);
        }
        rearrangeProducts($activeItem);

        // Hide utility nav menu if opened
        $utilityNavContent.addClass('hidden');
        $utilityNavTrigger.removeClass('active js-active-menu');

      });

      // On mouseleave, ALL of the children are faded out, and only once that's
      // complete will the items be positioned off screen:
      $primaryItems.on('mouseleave', function() {
        if (!isDesktop) { return; }

        clearTimeout(to);
        to = setTimeout(function() {
          collapseItems();
          collapseMenu();
        }, 300);
      });

      function rearrangeProducts($activeItem) {
        var $productsMenu = $('.js-gnav-dropdown', $activeItem);
        var $productsMenuCategories = $('.menu-ref', $productsMenu);
        var $gnav = $('.js-gnav-reordered', $productsMenu).show();
        $('.js-gnav-original', $productsMenu).hide();

        // If already rearranged, do nothing else
        if ($productsMenu.data('rearranged')) {
          return;
        }

        $productsMenu.data('rearranged', true);

        var columnHtml = [];
        var numOfCols = parseInt($productsMenu.data('columns'));

        // Loop through each category, read which column it belongs in and append accordingly
        $productsMenuCategories.each(function() {
          var index = $(this).data('column') - 1; // Start at 0 for the logic, start at 1 for the editor
          if (!columnHtml[index]) {
            columnHtml[index] = '';
          }
          // Append the html for this column with the element including itself
          columnHtml[index] += $('<div>').append($(this).clone()).html();
        });

        // Loop through the number of columns and append them
        for(var col = 0; col < numOfCols; col++) {
          $gnav.append('<div class="gnav-dropdown__content-column gnav-dropdown__content-column--' + (col+1) + '">' + columnHtml[col] + '</div>');
        }

        var $gnavMenuRef = $('.js-gnav-menu-ref', $gnav);
        // Reordering complete, initiate touts

        // Preload the touts
        $gnavMenuRef.each(function() {
          (new Image()).src = $(this).data('tout');
        });

        $gnavMenuRef.on('mouseenter', function() {
          var self = this;
          // Index of this element amongst all the gnav refs
          var index = $gnavMenuRef.index($(this));
          // Delete all existing touts
          $('.js-gnav-tout', $gnav).remove();
          $(self).removeClass('inactive');
          $gnavMenuRef.not(self).addClass('inactive');
          // Set image to this tout
          var $image = $('<img alt="" class="gnav__tout gnav__tout--' + (index+1) + ' js-gnav-tout" />');
          $image.attr({
            src: $(self).data('tout')
          });
          $gnav.append($image);
        });

        $gnav.on('mouseleave', function() {
          $('.js-gnav-tout', $gnav).remove();
          $gnavMenuRef.removeClass('inactive');
        });
      }

      // All of them always animate at the same time, so only listen to the
      // first one.
      if (transEndEvent) {
        $firstPrimaryDropdown.on(transEndEvent, function() {
          // If the menu is not expanded, remove the expanded class from all items,
          // positioning them back off screen.
          if (!expanded) {
            collapseItems();
          }
        });
      }

      // Global, non-contextual events go here.
      if (!this.attached) {
        Unison.on('change', function(bp) {
          bp = Unison.fetch.now();
          isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
          if (isDesktop) {
            // Above medium remove all instances of 'menu__item--selected'
            //
            $('.menu__item', $mainNav).removeClass('menu__item--selected');
            $body.removeClass('mobile-gnav-active');
          } else {
            // Below medium collapse any active menus
            collapseMenu();
            collapseItems();
          }
          // Hide the dynamic gnav
          $('.js-gnav-original').show();
          $('.js-gnav-reordered').hide();

          // Close active mobile menu:
          behavior.close();
        });
      }

      this.attached = true;
     },
    close: function() {
      var $header = $('.site-header');
      $('.menu__item, .menu__label, .menu-ref, .menu-ref__content', $header).removeClass('active js-active-menu menu__item--selected menu__item--expanded').attr('style', '');
      // Close any active ones
      if($('.site-header__main .menu__item.active', $header).length) {
        $('.site-header__main .menu__item.active', $header).removeClass('active menu__item--expanded js-active-menu');
      }
      $('.js-nav--primary', $header).removeClass('site-header__section--inverted');
      // Check if no gnav utility link is active
      // setTimeout(function() {
      //   if(!$('.js-nav--utility').find('.gnav-util__link.active, .js-gnav-util-trigger.active').length) {
      //     $('body').removeClass('gnav-active');
      //   }
      // }, 100);
      $('.js-nav--primary .js-gnav-util__content', $header).addClass('hidden');
    }
  };

  // Event for closing up entire gnav.
  $(document).on('gnav.readyToClose', function() {
    behavior.close();
  });

  return behavior;
})(jQuery);

var site = site || {};
site.template = site.template || {};

(function($) {

Drupal.behaviors.offers = {
  linkedBasedOffer: function() {
    if ($.cookie('offer_info') == null || $.cookie('offer_info').length == 0) {
      return;
    }
    var offer_code = $.cookie('offer_info').split(":")[1];
    site.onLoadRpc.requests.push({
      method: 'offer.linkedOfferState',
      params: [offer_code],
      onSuccess: function(data) {
        var message = data.result.value.linked_offer.offer_message;
        var template = offer_code;
        var rendered = site.template.get({
          name: template,
          data: {message : message}
        });
        generic.overlay.launch({
          content: rendered
        });
      },
      onError: function(data) {}
    });
  },

  attach: function(context, settings) {
    if (this.attached) {
      return;
    }
    this.attached = true;

    this.linkedBasedOffer();
  },

  attached: false
};

})(jQuery);

(function($, site, generic) {

site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];

site.onLoadRpc.init = function() {
  // Analytics stuff
  site.onLoadRpc.requests.push({
    method:  'analytics.userinfo',
    params:  [{}],
    onSuccess: function(data) {
      if ((data.result === undefined) || (data.result.value == null) || data.result.value.sorted == null) {
        return null;
      }

      // Set the cookie right away. Looked at combining it, but too many possible bugs this late before launch.
      // Also, Adobe libs load later than this JS does most of the time, so the s object is often undef at this point.
      generic.cookie('Auser', decodeURI(data.result.value.sorted + '-' + data.result.value.ident), { path: '/' });

      // still store in tms_page_data, will use the cookie only as a backup.
      if (typeof tms_page_data != 'undefined') {
        if (typeof tms_page_data.tms_page_info != 'undefined') {
          tms_page_data.tms_page_info.user_info = data.result.value;
        }
      }
    },
    onFailure: function() {}
  });

  // User stuff
  var signedIn = ((site.userInfoCookie.getValue('signed_in') - 0 === 1) || (site.userInfoCookie.getValue('csr_logged_in') == 1));
  if (signedIn) {
      var signOutDuration = Drupal.settings.signout_duration ? Drupal.settings.signout_duration : 15;
    site.onLoadRpc.requests.push({
      method:   'user.fullData',
      params:   [{}],
      onSuccess : function(data) {
        // This is for the Phone Order app to recognize values on Drupal pages
        if (!data || !data.result) {
          return;
        }

        var val = data.result.value;

        if ((val.csr_email !== undefined) && (val.csr_email !== null)){
          $('#csr_header_holder').removeClass('hidden');
        }

        // Set a body class attribute based on user logged in status
        $('body').addClass( (val.signed_in === 1) ? 'elc-user-state-logged-in' : 'elc-user-state-anonymous' );

        // Populate user data
        $('[data-pg-object="user"] > [data-pg-prop], [data-pg-object="user"][data-pg-prop]').each(function() {
          var $me = $(this);
          $me.html(val[$me.attr('data-pg-prop').toLowerCase()]);
        });

        // auto-signout after 15 minutes
        if ((document.location.protocol == 'https:') && /(^|;(\s+?)?)debug_noTimeout=1($|;)/.test(document.cookie) === false) {
          window.setTimeout(function() {
            var returnTo = '&RETURN_URL=' + encodeURIComponent(window.location.pathname + window.location.hash);
            document.location.href = '/account/signin.tmpl?_SUBMIT=signout&timeout=1' + returnTo;
            }, signOutDuration * 60 * 1000);
        }

        $(document).trigger('user.loaded', val);
      },
      onFailure : function() {}
    });
  } else {
    // generic.user.setUser({});
    $('body').addClass('elc-user-state-anonymous');
    $(document).trigger('user.loaded', {});
  }
};

/*
 * site.onLoadRpc.requests - a global array of RPC request objects
 * must be initialized pre-DOM-load and formatted like this:
 * [
 *     {
 *         "method":   "user.json",
 *         "params":   [{}],
 *         "getParams" : function () { return [{}] },  * one of 'params' or 'getParams' is required
 *         "onSuccess" : function () { },
 *         "onFailure" : function () { }
 *     }
 * ]
 */
site.onLoadRpc.fetch = function() {
  var requests = site.onLoadRpc.requests || [];
  var rLen     = requests.length;
  var queryVals = [];

  for (var i = 0, len = rLen; i < len; i++) {
    var postMethod = requests[i].method || 'rpc.form';

    if (typeof requests[i].getParams === 'function') {
      requests[i].params = requests[i].getParams();
    }

    queryVals[i] = {
      method: postMethod,
      params: requests[i].params,
      id: i + 1
    };
  }

  if (!queryVals.length) {
    return;
  }

  var successHandler = function(data) {
    for (var i = 0, len = rLen; i < len; i++) {
      var fn = requests[i].onSuccess;
      if (typeof fn !== 'function') {
        continue;
      }
      fn( data[i] );
    }
  };

  var url = generic.jsonrpc.url || '/rpc/jsonrpc.tmpl';
  var options = {};

  // ELCTWO-571 requires that we pass brand, region, and locale ids to ensure proper responses
  // on the pg side for drupal sites.  To accomplish this we pass 'addl_url_params' within the arguments.
  // This snippets searches for such entries and adds 'em to the request url.
  var url_params = '';
  $(queryVals).each(function() {
    if (this.params[0].url_params) {
      if (this.params[0].url_params.charAt(0) === '&') {
        url_params += this.params[0].url_params;
      }
      else {
        url_params += '&' + this.params[0].url_params;
      }
    }
  });
  if (url_params !== '') {
    url += '?' + url_params.substring(1);
  }

  options.data = $.param({JSONRPC: JSON.stringify(queryVals)});

  options.type = 'POST';
  options.success = function(data, textStatus, response) {
    successHandler(data, textStatus, response);
  };
  options.error = function(jqXHR, textStatus, errorThrown) { console.log(jqXHR, textStatus, errorThrown); };
  $.ajax(url, options);
};

$(function() {
  $(document).trigger('onLoadRpc.fetch');
  // Set user cookie
  site.userInfoCookie.init();
  site.onLoadRpc.init();
  site.onLoadRpc.fetch();

    if (window.location.hash === '#sign-in') {
      setTimeout(function () {
        $('#return-user-link').trigger('click');
      }, 500);
    }
    if (window.location.hash === '#new-account') {
      setTimeout(function () {
        $('#new-account-link').trigger('click');
      }, 500);
    }
});

})(jQuery, window.site || {}, window.generic || {});

var generic = generic || {};

(function($) {
  'use strict';

  // Route the old perlgem overlay method to colorbox:
  generic.overlay = {
    launch : function(args, event) {
      if (typeof event !== 'undefined' ) {
        event.preventDefault();
      }

      // ColorBox args sent along
      var cboxArgs = {
        'height': 'auto',
        'width': '768px',
        'margin': 'auto',
        'opacity': 1,
        'transition': 'none',
        'onComplete': function() {
          $(document).trigger('colorbox.complete');
        }
      };

      // Smoosh in any overrides from other calls, looks like args.cssStyle
      _.extend(cboxArgs, args);
      _.extend(cboxArgs, args.cssStyle); // get height/width overrides

      // trigger 'colorbox.complete' event even if args.onComplete exists
      if (typeof args.onComplete === 'function') {
        cboxArgs.onComplete = function() {
          $(document).trigger('colorbox.complete');
          args.onComplete.call(this);
        };
      }

      // When mobile, override any height/width and set to 100%
      if (window.innerWidth <= 768) {
        _.extend(cboxArgs, {
          'height': '100%',
          'width': '100%',
          'transition': 'none'
        });
      }

      // Actual content of the overlay
      if (typeof args.content !== 'undefined') {
        cboxArgs.html = args.content;
      }

      // A custom class each launcher has the option of setting
      if (typeof args.cssClass !== 'undefined') {
        cboxArgs.className = args.cssClass;
      }

      $(document).off('colorbox.complete').on('colorbox.complete', function() {
        // Scroll to an anchor, if sent over
        if (typeof args.inPageAnchor !== 'undefined') {
          $('#cboxLoadedContent').scrollTo($('#' + args.inPageAnchor), 50);
        }

        // reattach Drupal.behaviors to non-inline content
        if (!args.inline) {
          Drupal.attachBehaviors($('#cboxLoadedContent'));
        }
      });

      // Launch it
      $.colorbox(cboxArgs);
    },

    initLinks: function() {
      // Give us access to the parent scope so we can hit .launch()
      var self = this;
      // Links are tiggered via class, but indicate if already processed
      var $triggers = $('.overlay-link:not(.overlay-ready)').addClass('overlay-ready');

      // Depending on the type of link, the overlay needs to do something unique
      $triggers.each( function() {
        var args = {
            cssStyle: {}
          }, // args sent to overlay
          linkClassNames = $(this).attr('class'), // class name sent to colorbox
          linkHref = $(this).attr('href'), // actual href
          linkHrefWithEmbed = linkHref,
          inPageAnchor = $(this).data('inpage-anchor'), // see try/catch below
          overlayElement = $(this).data('overlay-content'); // use an existing element as content

        // used in overlay linking below
        var urlParts = document.createElement('a'); //
        urlParts.href = linkHref; //

        // Parse height options out of the link's class
        var widthRegexResults = linkClassNames.match(/overlay-width-(\d+)/);
        if (widthRegexResults) {
          args.cssStyle.width = widthRegexResults[1];
        }
        // Parse width options
        var heightRegexResults = linkClassNames.match(/overlay-height-(\d+)/);
        if (heightRegexResults) {
          args.cssStyle.height = heightRegexResults[1];
        }
        // Add a custom class, optionally
        var cssClassRegexResults = linkClassNames.match(/overlay-addclass-([a-z\-\_]+)/);
        if (cssClassRegexResults) {
          args.className = cssClassRegexResults[1];
        }

        // Make sure embed doesn't already exist. This gets added form internal
        // drupal embeddable urls
        if (typeof overlayElement !== 'undefined') {
          args.content = $(overlayElement).html();
        } else {
          try {
            if( !linkHref.match(/[\&\?]embed=1($|&)/)) {
              linkHrefWithEmbed = ((urlParts.pathname.charAt(0) == "/") ? urlParts.pathname : "/" + urlParts.pathname) + (urlParts.search === "" ? "?" : urlParts.search+"&") + "embed=1" + urlParts.hash;

              // Retain original link if it included the protocol.
              if(linkHref.match(/https?:\/\//)) {
                linkHrefWithEmbed = urlParts.protocol + "//" + urlParts.host + linkHrefWithEmbed;
              }
            }
          } catch(e) {
            linkHrefWithEmbed = linkHref;
          }

          // Fix the link within the page
          $(this).attr('href', linkHrefWithEmbed);
          // But this is actually used to launch overlay
          args.href = linkHrefWithEmbed;
        }

        // scrollTo behavior if we have a data attribute
        if (typeof inPageAnchor !== 'undefined') {
          args.inPageAnchor = inPageAnchor;
        }

        // Launch a colorbox overlay
        $(this).on('click', function(e) {
          // use our canonical launch function for all the goodies
          self.launch(args, e);
        });

      }); // .each()

    }, // initLinks

    hide: function() {
      $.colorbox.close();
    },

    getRBKeys: function() {
      generic.rb.language = generic.rb("language");
      generic.rb.language.rb_close = generic.rb.language.get('close');
    }
  };

  ($)(function(){
    generic.overlay.getRBKeys();
    generic.overlay.initLinks();
  });

})(jQuery);


var site = site || {};
var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};
window.pwr = window.pwr || function () {
  (pwr.q = pwr.q || []).push(arguments);
};

(function($) {
  var pr_products = [];
  var drupalPRSettings = Drupal.settings.analytics ? Drupal.settings.analytics.power_reviews_enh_enabled : false;
  var translationCopyUpdated = false;
  // Set up PowerReviews data on product.init
  $(document).on('product.init-pr', '.js-product-pr', function() {
    if (typeof pwr === 'function' && typeof Drupal.settings.power_reviews !== 'undefined' && $(this).data('product-id') != '') {
      var product = prodcat.data.getProduct($(this).data('product-id'));

      var reviewsnippet_id = 'pr-reviewsnippet-' + product.PROD_BASE_ID + '-' + new Date().getTime();
      var $reviewsnippet = $('.review-snippet', this);
      $reviewsnippet.attr('id', reviewsnippet_id);

      if (product && $reviewsnippet.length && !$reviewsnippet.hasClass('reviews-processed')) {
        $reviewsnippet.addClass('reviews-processed');

        var page_id = Drupal.settings.power_reviews.page_id || product.PROD_BASE_ID;
        var review_wrapper_url = (Drupal.settings.power_reviews.review_wrapper_url || '/review/create') + '?page_id=' + page_id;
        //SmashBox CA FR Config Changes
        var pr_locale = document.location.hostname.search('fr.smashbox.ca') != -1 ? Drupal.settings.power_reviews.locale_fr : Drupal.settings.power_reviews.locale;
        var pr_merchant_id = document.location.hostname.search('fr.smashbox.ca') != -1 ? Drupal.settings.power_reviews.merchant_id_fr : Drupal.settings.power_reviews.merchant_id;

        //Locale & Merchant id configuration for Hub and Multi sites
        if (typeof Drupal.settings.power_reviews.merchant_ids != 'undefined') {
          var pr_locale_merchant_id = get_power_reviews_configuration();
          pr_locale = pr_locale_merchant_id[0]['locale'];
          pr_merchant_id = pr_locale_merchant_id[0]['merchant_id'];
        }

        var pr_product = {
          api_key: Drupal.settings.power_reviews.api_key,
          merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
          merchant_id: pr_merchant_id,
          locale: pr_locale,
          page_id: page_id,
          review_wrapper_url: review_wrapper_url,
          components: {
            ReviewSnippet: reviewsnippet_id,
          }
        };

        if ($(this).hasClass('product-full')) {
          pr_product.on_render = function(config, data) {
            var review_count = data.review_count;
            if (review_count > 0) {
              $('.spp_reviews_button').removeClass('hidden');
            } else {
              $('.spp_reviews_button').addClass('hidden');
            }
            // MTA-225 Analytics enhancements for read/write reviews Starts
            if (drupalPRSettings) {
              // Track sort option in reviews page
              $('#pr-rd-sort-by').once().on('change', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  var obj = {
                    sort_option: $('option:selected', this).text()
                  };
                  site.track.sortReviews(obj);
                }
              });

              // Tracking search action in review section
              $('.pr-rd-search-reviews-icon-button').once().on('click', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  var searchTerm = $(this).siblings(':input').attr('value');
                  if (searchTerm && searchTerm !== '') {
                    var obj = {
                      search_term: searchTerm
                    };
                    site.track.searchReviews(obj);
                  }
                }
              });

              // Track write revew button in reviews section
              $('.spp_reviews_button .review_link, .pr-snippet-write-review-link').once().on('click', function() {
                if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
                  site.track.reviewWriteStart();
                }
              });

              // Tracking filter action in review section
              $('.pr-multiselect').once('js-multi-select').each(function() {
                var selectElement = $(this).find('.pr-multiselect-options ul li input[type="checkbox"]');
                selectElement.on('change', function() {
                  var checkSelected = $(this).is(':checked');
                  if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && checkSelected) {
                    $(this).attr('data-selected', 'selected');
                    var selectedFilter = selectElement.filter('[data-selected="selected"]');
                    var appliedFiltersArray = [];
                    var filter_type_html = selectElement.closest('.pr-multiselect-options').siblings('.pr-multiselect-button').find('span:not(.pr-multiselect-count)').html();
                    var filter_type = '';
                    if (filter_type_html) {
                      filter_type = filter_type_html.replace(/<\/?span[^>]*>|&nbsp;/g, '').trim();
                    }
                    selectedFilter.each(function() {
                      appliedFiltersArray.push($(this).siblings('span').text());
                    });
                    var obj = {
                      sort_option: filter_type + ' | ' + appliedFiltersArray.join(', ')
                    };
                    site.track.filterReviews(obj);
                  } else if (!checkSelected) {
                    $(this).attr('data-selected', 'deselected');
                  }
                });
              });
            }
            // MTA-225 Analytics enhancements for read/write reviews Ends

            if(!translationCopyUpdated) {
              translationCopyUpdated = true;
              var reviewers_recommend = site.translations.product.reviewers_recommend || ' OF REVIEWERS RECOMMEND THIS PRODUCT';
              var pros_title = site.translations.product.pros_title || 'REVIEWERS FIND THIS PRODUCT BEST FOR';
              var search_button_text = site.translations.product.search_button_text || 'GO';
              var sort_label = site.translations.product.sort_label || 'Sort: ';
              var filter_label = site.translations.product.filter_label || 'Filter: ';
              var helpful_text_array = (site.translations.product.helpful_text || 'yes # |').split('#');
              var not_helpful_text_array = (site.translations.product.not_helpful_text || 'no # ').split('#');
              var out_of_5_text = site.translations.product.out_of_5_text || ' OUT OF 5';

              $('.pr-multiselect', '.pr-rd-review-header-contents').not('.pr-multiselect-button-agerange').remove();

              $('.pr-reco-value', '.featured_header .pr-review-snapshot-snippets').attr('data-after-content', reviewers_recommend);
              $('.pr-review-snapshot-tags dt', '.featured_header .pr-review-snapshot-block-pros').attr('data-after-content', pros_title);
              $('.pr-rd-review-header-contents', '.featured_header .pr-rd-main-header').attr('data-before-content', filter_label);
              $('.pr-rd-sort-group', '.featured_header .pr-rd-review-header-sorts').attr('data-before-content', sort_label);
              $('.pr-snippet-rating-decimal', '.featured_header .pr-review-snapshot-snippets').attr('data-after-content', out_of_5_text);

              var sheet = document.createElement('style');
              sheet.innerHTML = '.pr-helpful-yes .pr-helpful-count:before{content: "' + helpful_text_array[0] + '"}';
              sheet.innerHTML += '.pr-helpful-yes .pr-helpful-count:after{content: "' + helpful_text_array[1] + '"}';
              sheet.innerHTML += '.pr-helpful-no .pr-helpful-count:before{content: "' + not_helpful_text_array[0] + '"}';
              sheet.innerHTML += '.pr-helpful-no .pr-helpful-count:after{content: "' + not_helpful_text_array[1] + '"}';
              sheet.innerHTML += '.featured_header .pr-rd-search-reviews-icon-button .pr-search-icon:after{content: "' + search_button_text + '"}';
              document.body.appendChild(sheet);
            }

          };
          pr_product.on_read_reviews_click = function() {
            $('.js-pr-rating-tab', $('.js-responsive-tabs--powerreviews')).trigger('click');
            $('html, body').animate({
              'scrollTop': $('#pr-reviewdisplay').offset().top
            }, 1000);
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
              site.track.reviewRead();
            }
          };
          pr_product.on_write_review_click = function(config, data) {
            if (typeof site !== 'undefined' && typeof site.track !== 'undefined') {
              site.track.reviewWriteStart();
            }
            var war_link = $('.pr-snippet-write-review-link').attr('href');
            if (typeof war_link !== 'undefined' && war_link !== '') {
              window.location.href = war_link;
            }
          };
          pr_product.structured_data_product_id = 'pr_seo_snippet';
          pr_product.components.ReviewDisplay = 'pr-reviewdisplay';
          var image_url = typeof product.IMAGE_L == 'object' ? encodeURI(product.IMAGE_L[0]) : encodeURI(product.IMAGE_L);
          var url = encodeURI(product.url);
          var base_url = window.location.protocol + '//' + document.location.hostname;

          pr_product.product = {
            name: strip_html_tags(product.PROD_RGN_NAME),
            url: base_url + url,
            image_url: base_url + image_url,
            description: strip_html_tags(product.DESCRIPTION),
            category_name: product.DEFAULT_CAT_ID,
            upc: product.defaultSku.UPC_CODE,
            brand_name: 'Smashbox',
            price: product.defaultSku.PRICE,
            in_stock: (!!product.isShoppable).toString()
          };
        }

        pr_products.push(pr_product);
      }

      if (Drupal.settings.power_reviews.show_ask_question && $(this).hasClass('product-full') && !$('#pr-questionsnippet').hasClass('question-processed')) {
        $('#pr-questionsnippet').addClass('question-processed');
        var page_id = Drupal.settings.power_reviews.page_id || product.PROD_BASE_ID;

        var pr_product_question = {
          api_key: Drupal.settings.power_reviews.api_key,
          locale: Drupal.settings.power_reviews.locale,
          merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
          merchant_id: Drupal.settings.power_reviews.merchant_id,
          page_id: page_id,
          review_wrapper_url: review_wrapper_url,
          on_view_answers_click: function() {
            $('.js-pr-ask-tab', $('.js-responsive-tabs--powerreviews')).trigger('click');
            $('html, body').animate({
              'scrollTop': $('#pr-questiondisplay').offset().top
            }, 1000);
          },
          components: {
            QuestionSnippet: 'pr-questionsnippet',
            QuestionDisplay: 'pr-questiondisplay',
          }
        };
        pr_products.push(pr_product_question);
      }
      $(document).trigger('products.init-finish');
    }
    $('.js-product-review').on('click', 'a.pr-snippet-write-review-link, .review_link', function() {
      var currentUrl = $(this).attr('href');
      var redirectUrl = currentUrl + '&redirect=' + window.location.href;
      location.href = redirectUrl;
      return false;
    });
  });

  // Render power reviews after products have been initialized
  $(document).on('products.init-start', function() {
    pr_products = [];
    $('.js-product-pr').trigger('product.init-pr');
  });

  // Render power reviews after products have been initialized
  $(document).on('products.init-finish', function() {
    if (typeof pwr === 'function' && typeof Drupal.settings.power_reviews != 'undefined') {
      pwr('render', pr_products);
    }
  });

  // MTA-225 Track thumbs up rating for a review
  $(document).on('click', '.pr-rd-helpful-action .pr-helpful-yes', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs up'
      };
      site.track.rateReviews(obj);
    }
  });

  // MTA-225 Track thumbs down  rating for a review
  $(document).on('click', '.pr-rd-helpful-action .pr-helpful-no', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'thumbs down'
      };
      site.track.rateReviews(obj);
    }
  });

  // MTA-225 Track flagging reviews in review page
  $(document).on('click', '.pr-rd-flag-review-container .pr-underline, .pr-flag-review .pr-flag-review-btn-submit', function() {
    if (typeof site !== 'undefined' && typeof site.track !== 'undefined' && drupalPRSettings) {
      var obj = {
        event_action: 'flag this review'
      };
      site.track.rateReviews(obj);
    }
  });

  function strip_html_tags(str) {
    if ((str === null) || (str === '')) {
      return false;
    } else {
      str = str.toString();
      return str.replace(/<[^>]*>/g, '');
    }
  }

  // Get merchant id and locale for power review based on locale set on the page
  function get_power_reviews_configuration() {
    var pr_configuration = Drupal.settings.power_reviews.merchant_ids;
    var locale = $.cookie('LOCALE') != null || $.cookie('LOCALE') !== '' ? $.cookie('LOCALE') : 'en_CH';
    if (pr_configuration[locale]) {
      var pr_conf = pr_configuration[locale];
      var pr_locale_merchant_id = new Array();
      $.each (pr_conf, function(locale, merchant_id) {
        pr_locale_merchant_id.push({'locale': locale, 'merchant_id': merchant_id});
      });
      return pr_locale_merchant_id;
    }
  }
})(jQuery);

var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

var site = site || {};
site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];

(function($) {
  prodcat.data.collectProductIds = function($context) {
    var prodIds = [];
    $('[data-product-id]', $context).each(function() {
      var $this = $(this);
      var prodId = $this.attr('data-product-id');
      // Check if a prodId exists
      if (!prodId) {
        return null;
      }
      var insert = true;
      var i;
      for (i = prodIds.length - 1; i > -1; i--) {
        if (prodIds[i] === prodId) {
          insert = false;
          break;
        }
      }
      if (insert) {
        prodIds.push(prodId);
      }
      insert = true;
    });
    return prodIds;
  };

  /**
   * Retrieves product data from data store. Folds full sku data into product.skus array
   * @param {String} prodId
   */
  prodcat.data.getProduct = function(prodId) {
    if (!prodcat.data.store.products[prodId]) {
      return null;
    }
    var prodData = $.extend(true, {}, prodcat.data.store.products[prodId]);
    _.each(prodData.skus, function(skuId, idx) {
      prodData.skus[idx] = prodcat.data.getSku(skuId);
    });
    return prodData;
  };

  prodcat.data.getSku = function(skuId) {
    skuId = skuId + ''; // Has to be a string to run indexOf
    skuId = skuId.indexOf('SKU') === 0 ? skuId : 'SKU' + skuId;
    var skuData = prodcat.data.store.skus[skuId];
    if (!skuData) {
      return null;
    }
    return skuData;
  };

  /**
   * Sanitize the product data to ensure a consistent data structure
   */
  prodcat.data.sanitizeProductData = function(product) {
    // Force the following fields to be an array even if they're originally a string
    var productImages = ['LARGE_IMAGE', 'MEDIUM_IMAGE', 'SMALL_IMAGE'];
    var fetchImage = '';
    var i;
    for (i = productImages.length; i--;) {
      fetchImage = productImages[i];
      if (_.isUndefined(product[fetchImage])) {
        continue;
      }
      product[fetchImage] = typeof product[fetchImage] === 'string' ? [ product[fetchImage] ] : product[fetchImage];
    }
    return product;
  };

  $(document).on('prodcat.products.update', function(e, productsArray, deepCopy) {
    prodcat.data.updateProducts(productsArray, deepCopy);
  });

  prodcat.data.updateProducts = function(productsArray, deepCopy) {
    var self = this;
    deepCopy = deepCopy === false ? deepCopy : true; // do a deep copy of the product data by default
    _.each(productsArray, function(newProd) {
      if (!newProd) {
        return;
      }
      var targetProd = $.extend(deepCopy, {}, newProd);
      var oldProd = prodcat.data.store.products[newProd.PRODUCT_ID];

      if (_.isArray(newProd.skus)) {
        prodcat.data.updateSkus(targetProd.skus);
        targetProd.skus = _.map(targetProd.skus, function(sku) {
          return sku.SKU_ID;
        });
      }

      var prod = prodcat.data.sanitizeProductData(_.isObject(oldProd) ? _.extend(oldProd, targetProd) : targetProd);

      // Set defaultSku to the first sku if not already set:
      if (_.isUndefined(prod.defaultSku) && prod.skus && prod.skus.length) {
        prod.defaultSku = self.getSku(prod.skus[0]);
      }

      prodcat.data.store.products[targetProd.PRODUCT_ID] = prod;
    });

    $(document).trigger('prodcat.products.updated', prodcat.data.store.products);
  };

  /**
   * Sanitize the sku data to ensure a consistent data structure
   */
  prodcat.data.sanitizeSkuData = function(sku) {
    // Remove any "product" keys from the sku object to prevent recursion errors down the road.
    sku.product = undefined;

    // Force the following fields to be an array even if they're originally a string
    var skuImage = ['LARGE_IMAGE', 'MEDIUM_IMAGE', 'SMALL_IMAGE'];
    var fetchImage = '';
    var i;
    for (i = skuImage.length; i--;) {
      fetchImage = skuImage[i];
      if (_.isUndefined(sku[fetchImage])) {
        continue;
      }
      sku[fetchImage] = typeof sku[fetchImage] === 'string' ? [ sku[fetchImage] ] : sku[fetchImage];
    }

    return sku;
  };

  prodcat.data.updateSkus = function(skusArray) {
    _.each(skusArray, function(newSku) {
      newSku = prodcat.data.sanitizeSkuData(newSku);
      var oldSku = prodcat.data.store.skus[newSku.SKU_ID];
      prodcat.data.store.skus[newSku.SKU_ID] = _.isObject(oldSku) ? _.extend(oldSku, newSku) : newSku;
    });
  };

  prodcat.data.init = function() {
    var key;
    prodcat.data.store = {
      categories: {},
      products: {},
      skus: {}
    };

    if (typeof page_data === 'undefined' || !page_data) {
      return null;
    }

    function _catStore(newCat) {
      var oldCat = prodcat.data.store.categories[newCat.CATEGORY_ID];
      var targetCat = $.extend(true, {}, newCat);
      if (_.isArray(targetCat.products)) {
        prodcat.data.updateProducts(targetCat.products);
      }
      targetCat.products = _.map(targetCat.products, function(prod) {
        return prod.PRODUCT_ID;
      });
      prodcat.data.store.categories[targetCat.CATEGORY_ID] = _.isObject(oldCat) ? _.extend(oldCat, targetCat) : targetCat;
    }

    for (key in page_data) {
      if (!page_data[key]) {
        continue;
      }
      if (page_data[key].categories && _.isArray(page_data[key].categories)) {
        _.each(page_data[key].categories, _catStore);
      }
      if (page_data[key].products && _.isArray(page_data[key].products)) {
        prodcat.data.updateProducts(page_data[key].products);
      }
      if (page_data[key].product && _.isObject(page_data[key].product)) {
        $(document).trigger('prodcat.products.update', [page_data[key].product]);
        prodcat.data.updateProducts([page_data[key].product]);
      }
    }

    $(document).trigger('prodcat.data.initalized', prodcat.data.store);
  };

  prodcat.data.pids = prodcat.data.collectProductIds();
  // Prevent making an empty call if no prodIds are available.
  if (prodcat.data.pids.length > 0) {
    site.onLoadRpc.requests.push({
      method: 'prodcat.querykey',
      getParams: function() {
        var pids = prodcat.data.pids;
        return [{
          products: pids,
          query_key: 'catalog-mpp-volatile'
        }];
      },
      onSuccess: function(response) {
        if (
          !response ||
          !response.result ||
          !response.result.value ||
          !response.result.value.products
        ) {
          return;
        }

        var prods = _.compact(response.result.value.products);
        prodcat.data.updateProducts(prods);

        _.each(prods, function(prod) {
          var prodSlctr = "[data-product-id='" + prod.PRODUCT_ID + "']";
          $(prodSlctr).trigger('inv_status_data:updated');
        });

        if (prods.length > 0) { // can't think of a reason why this WOULD be empty, but check, just in case
          // trigger a custom event, letting all who care know that we've updated inventory status data for every product
          $(document).trigger('inv_status_data:finished');
        }

        $(document).trigger('prodcat.data.query.success');
      }
    });
  }

  prodcat.data.isPaletteMultiSku = function(prodId) {
    var prodData = prodcat.data.getProduct(prodId);
    return !!prodData.isPaletteMultiSku;
  };

  prodcat.data.isPaletteMultiProduct = function(prodId) {
    if (!page_data['spp_palette_multiprod']) {
      return false;
    }
    if (!page_data['spp_palette_multiprod']['products']) {
      return false;
    }
    var prodIds = _.pluck(page_data['spp_palette_multiprod']['products'], 'PRODUCT_ID');
    return !!(prodIds.indexOf(prodId) > -1);
  };

  prodcat.ui.getContextProductEl = function($el) {
    var $closestProdEl = $el.closest('.js-product');
    return $closestProdEl;
  };
  prodcat.ui.getProductEl = function($el) {
    var $closestProdEl = $el.closest('.js-product');
    var prodId = $closestProdEl.attr('data-product-id');
    return $(".js-product[data-product-id='" + prodId + "']");
  };

  /**
   * Pull data from page_data and store internally.
   */
  Drupal.behaviors.prodcatDataInit = {
    attached: false,
    attach: function() {
      if (!this.attached) {
        prodcat.data.init();
      }
      this.attached = true;
    }
  };
})(jQuery);

var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};

(function($, generic) {
  'use strict';

  /**
  * Adds SKUs to cart.
  */
  prodcat.ui.addToCart = function(args) {
    var skuBaseId;
    if (args.skuData && args.skuData.SKU_BASE_ID) {
      skuBaseId = args.skuData.SKU_BASE_ID;
    } else if (args.skuBaseId) {
      skuBaseId = args.skuBaseId;
    } else {
      return;
    }

    var quantity;
    if (typeof args.quantity !== 'undefined') {
      quantity = args.quantity;
    } else {
      quantity = 1;
    }

    var catBaseId = '';
    if (args.skuData && args.skuData.PARENT_CAT_ID) {
      var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
      if (matchResult) {
        catBaseId = matchResult[0];
      }
    }

    if (typeof args.REPLENISHMENT_FREQ !== 'undefined' && args.REPLENISHMENT_FREQ !== false) {
      args.itemType = 'replenishment';
      args.action = 'add';
      args.add_to_cart = 1;
      args.REPLENISHMENT_FREQ = args.REPLENISHMENT_FREQ;
    }

    args.skus = args.skus || (_.isString(skuBaseId) ? [skuBaseId] : skuBaseId);
    args.itemType = args.itemType || 'cart';
    args.QTY = quantity || args.QTY;
    args.INCREMENT = 1; // INCREMENT only needs to be true.

    // Conditionally add a CAT_BASE_ID key to the list of parameters to send
    var cbid = args.CAT_BASE_ID || catBaseId;
    if (cbid.length > 0) {
      args.CAT_BASE_ID = cbid;
    }

    generic.checkout.cart.updateCart({
      params: args,
      onSuccess: function(r) {
        var resultData = r.getData();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.success', [resultData]);
      },
      onFailure: function(ss) {
        var errorObjectsArray = ss.getMessages();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.failure', [errorObjectsArray]);
      }
    });
  };

  /**
  * Adds a SKU to the user's primary favorites list.
  * @param {Object} args
  * @param {Object} args.skuData a set of key-value pairs describing a SKU
  * @param {String, Number} args.skuData.SKU_BASE_ID Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
  * @param {String} args.skuData.PARENT_CAT_ID Category ID for the SKU
  * @param {String, Number} args.skuBaseId Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
  */
  prodcat.ui.addToFavorites = function(args) {
    var params = {
      _SUBMIT: 'alter_collection',
      action: 'add'
    };
    if (args.action) {
      params.action = args.action;
    }

    var skuBaseId;
    if (args.skuData && args.skuData.SKU_BASE_ID) {
      skuBaseId = args.skuData.SKU_BASE_ID;
    } else if (args.skuBaseId) {
      skuBaseId = args.skuBaseId;
    } else {
      return;
    }
    params.SKU_BASE_ID = skuBaseId;

    if (args.skuData && args.skuData.PARENT_CAT_ID) {
      var matchResult = args.skuData.PARENT_CAT_ID.match('[0-9]+');
      if (matchResult) {
        params.CAT_BASE_ID = matchResult[0];
      }
    }

    if ($.cookie('csrftoken')) {
      params._TOKEN = $.cookie('csrftoken');
    }

    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [params],
      onSuccess: function(jsonRpcResponse) {
        var d = jsonRpcResponse.getData();
        var r = d.ac_results[0].result;
        var cr;
        if (params.action !== 'delete') {
          if (r.KEY === 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
            cr = jsonRpcResponse.getCartResults();
            $(document).trigger('addToWishlist.exists', [cr, args.$el]);
          } else if (r.SUCCESS === 1 || r.KEY === 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
            cr = jsonRpcResponse.getCartResults();
            $(document).trigger('addToWishlist.success', [cr, args.$el]);
          }
          $(document).trigger('product.favorites', [args.$el, 'success', skuBaseId]);
        } else {
          $(document).trigger('product.favorites', [args.$el, 'removed', skuBaseId]);
        }
      },
      onFailure: function(ss) {
        var errorObjectsArray = ss.getMessages();
        $(document).trigger('addToWishlist.failure', [errorObjectsArray]);
      }
    });
  };

  prodcat.ui.grids = (function() {
    var grids = [];
    return $.extend(prodcat.ui.grids || {}, {
      add: function(grid) {
        if (_.indexOf(grids, grid) === -1) { // if we can't find the grid in our list
          grids.push(grid);
        }
      },
      clean: function(args) {
        if (args.before && typeof args.before === 'function') {
          args.before(grids);
        }
        site.util.grids.attach(grids);
        if (args.after && typeof args.before === 'function') {
          args.after(grids);
        }
        grids = [];
      }
    });
  }());

  // Default product listeners:
  // @TODO comment out all console.log
  $(document).on('product.init', '.js-product', function() {
    //console.log('product.init');

    var $product = $(this);
    var skuBaseId = $product.data('sku-base-id');
    var prodData = prodcat.data.getProduct($product.data('product-id'));

    // if defaultSku is Sold Out, pick first shoppable sku
    if (!!skuBaseId && !!prodData && !prodData.defaultSku.isShoppable && prodData.skus.length > 1) {
      var validSku = _.findWhere(prodData.skus, {isShoppable: 1});
      skuBaseId = !!validSku ? validSku.SKU_BASE_ID : skuBaseId;
    }

    var changeSppShade = function(shadeName) {
      if (!$product.hasClass('product-full')) {
        return;
      }

      shadeNameNoSpaces = decodeURIComponent(shadeName).split('_').join(' ');

      _.each(prodData.skus, function(sku) {
        if (shadeNameNoSpaces === sku.SHADENAME.toString()) {
          skuBaseId = sku.SKU_BASE_ID;
        }
      });
    };

    var changeSppFamily = function(family) {
      if (!$product.hasClass('product-full')) {
        return;
      }

      $product.trigger('product.showColorFamily', [family]);

      $(document).on('product.colorFamilyLoaded', function() {
        $product.trigger('product.showColorFamily', [family]);
      });
    };

    var routes = {
      '/shade/:shadeName': changeSppShade,
      '/family/:family': changeSppFamily
    };

    var router = Router(routes);
    router.init();

    if (!!skuBaseId) {
      $(this).trigger('product.skuSelect', [skuBaseId]);

      // Triggered to apply the route's shade name to both the hidden <select>
      // element and the visible replacement select.
      $(this).trigger('product.initSelects', [skuBaseId]);
    }

    $(this).trigger('product.initFavoritesTooltip', [$product]);

    $(this).trigger('product.updateInvStatus');
  });

  $(document).on('product.updateInvStatus', '.js-product', function() {
    //console.log('product.updateInvStatus');
    var $addBtn = $('.js-add-to-cart, .js-add-to-bag', this);
    var $faveLink = $('.js-add-to-favorites', this);
    var $price = $('.js-sku-price', this);
    var $statusList = $('.js-inv-status-list', this);
    var $skuSelect = $('.js-sku-select--v1', this);
    var $size = $('.js-product-size--shaded', this);
    var $breadcrumb = $('.js-product-full__breadcrumb', this);
    var skuBaseId = $addBtn.data('sku-base-id') || $(this).data('sku-base-id');
    var skuDataL2 = prodcat.data.getSku(skuBaseId);
    var $parentGrid;
    var parentGridOverrideStatus;
    var reorderStatuses = [ // these are the statuses that will trigger a product block to be reordered
      2, // Temporarily Sold Out
      3, // Coming Soon
      7 // Sold Out
    ];

    var reorderTest = function(l2Cache) {
      return _.contains(reorderStatuses, parseInt(l2Cache.INVENTORY_STATUS));
    };

    var reorderSoldOut = function(skuDataL2, el) {
      var $el = $(el);

      if (!_.isArray(skuDataL2)) {
        skuDataL2 = [skuDataL2];
      }

      if (_.every(skuDataL2, reorderTest)) {
        $el.closest('.js-grid-item').appendTo($el.closest('.js-grid-item').parent());
        prodcat.ui.grids.add($el.closest('.js-grid').get(0));
      }
    };

    if (!$addBtn.length) {
      return;
    }

    $parentGrid = $(this).parents('.js-product-grid');
    parentGridOverrideStatus = $parentGrid.data('sold-out-reordering-override');
    // Reordering sold out products (inv. status 7), to be last in display order on MPPs/anywhere they appear in a grid
    if ($parentGrid.is('.js-sold-out-reordering') && parentGridOverrideStatus !== 'off' || parentGridOverrideStatus === 'on') {
      reorderSoldOut(prodcat.data.getProduct($(this).data('product-id')).skus, this);
    }

    // disable add to bag to non shoppable skus AND restricted products (proper user validation will enable it again)
    var prodData = skuDataL2 ? prodcat.data.getProduct(skuDataL2.PRODUCT_ID) : {};
    if (((skuDataL2 && !skuDataL2.isShoppable) || (prodData && prodData.RESTRICT_GROUP === 1)) && (skuDataL2.INVENTORY_STATUS !== 3 && skuDataL2.INVENTORY_STATUS !== 2)) {
      $addBtn.addClass('button--disabled').attr('disabled', 'disabled');
    } else {
      $addBtn.removeClass('button--disabled').removeAttr('disabled');
    }
    //console.log('product ui changes button');

    // Hide Add to bag, favorites, price, size, status list and sku dropdown if the product is promotional(loyalty)
    if (skuDataL2 && skuDataL2.INVENTORY_STATUS === 6 && Drupal.settings.common && Drupal.settings.common.hide_elements_spp_loyalty) {
      $addBtn.hide().addClass('hidden');
      $faveLink.hide().addClass('hidden');
      $price.hide().addClass('hidden');
      $statusList.hide().addClass('hidden');
      $skuSelect.hide().addClass('hidden');
      $size.hide().addClass('hidden');
      $breadcrumb.css({'visibility': 'hidden'});
    }

    if (skuDataL2 && !!skuDataL2.PRICE2) {
      $(this).trigger('product.setSalePrice', [skuDataL2]);
    }
    $(document).trigger('product.updateInvStatusFinished');
  });

  $(document).on('product.updateInvStatusFinished', function() {
    //console.log('product.updateInvStatusFinished');
    prodcat.ui.grids.clean({
      before: function(grids) {
        _.each(grids, function(grid) {
          var $grid = $(grid),
              hasQuickshop = $grid.hasClass('product-grid--quickshop'),
              // not currently using, but can distinguish on a per grid basis which have inline quickshops
              isInlineQuickshop = $grid.hasClass('js-quickshop-style-inline');

          if (hasQuickshop && prodcat.ui.quickshop) {
            prodcat.ui.quickshop.reset($grid);
          }
        });
      },
      after: function(grids) {
        _.each(grids, function(grid) {
          var $grid = $(grid),
              hasQuickshop = $grid.hasClass('product-grid--quickshop'),
              // not currently using, but can distinguish on a per grid basis which have inline quickshops
              isInlineQuickshop = $grid.hasClass('js-quickshop-style-inline');

          if (hasQuickshop && prodcat.ui.quickshop) {
            prodcat.ui.quickshop.init($grid);
          }
        });
      }
    });
  });

  $(document).on('product.quickshopInit', function(e, methods) {
    //console.log('product.quickshopInit');
    prodcat.ui.quickshop = _.extend(prodcat.ui.quickshop || {}, methods);
  });

  $(document).on('product.skuSelect', '.js-product', function(e, skuBaseId) {
    //console.log('product.skuSelect');
    $(this).data('sku-base-id', skuBaseId);
    $('.js-add-to-cart, .js-add-to-bag', this).data('sku-base-id', skuBaseId);
    $('.js-add-to-favorites', this).data('sku-base-id', skuBaseId);
    $('.js-inv-status-list', this).data('sku-base-id', skuBaseId);
    $(this).trigger('product.skuDisplay', [skuBaseId]);
    $(this).trigger('product.updateInvStatus');

    if (Drupal.settings && Drupal.settings.common && Drupal.settings.common.loyalty_prices_enabled) {
      $(this).trigger('product.skuSelectInit', [skuBaseId]);
    }
  });

  $(document).on('product.skuSelectInit', function(event, skuBaseId) {
    $('select.js-sku-menu', this).val(skuBaseId);
    var sku = prodcat.data.getSku(skuBaseId);
    if (sku) {
      var content = site.template.get({
        name: 'product_sku_price',
        data: { defaultSku: sku }
      });
      $('.product-sku-price', this).html($(content).html());
    }
  });

  $(document).on('inv_status_data:updated', '.js-product', function() {
    //console.log('inv_status_data:updated');
    $(this).trigger('product.updateInvStatus');
  });

  // SPP links open in new window if spp_in_new_window=true in config
  if (Drupal.settings.common && Drupal.settings.common.spp_in_new_window) {
    $(document).on('click', '.js-spp-link', function() {
      $(this).attr('target', '_blank');
    });
  }
  var $jsAddToCart = $('.js-add-to-cart');
  $jsAddToCart.on('click', function(e) {
    e.preventDefault();
    var $addBtn = $(this);
    var defaultKitSku = $addBtn.parents('.js-spp-bundle-layout-flow').data('sku-base-id');
    if ($addBtn.data('button-status') === 'pending') {
      return null;
    } else {
      $(document).trigger('addToCart.buttonStatus', [$addBtn, 'pending']);
    }

    var kitSkus = $addBtn.data('kit-skus');
    var skuBaseId = $addBtn.data('sku-base-id');
    if ((!skuBaseId || skuBaseId.length < 1) && !kitSkus) {
      return null;
    }
    if (skuBaseId === '' && defaultKitSku !== undefined) {
      kitSkus = defaultKitSku + ',' + kitSkus;
    }
    // Account for adding multiple skus to bag
    var args = {};
    skuBaseId = String(skuBaseId);
    if (skuBaseId.indexOf(',') >= 0) {
      args.skuBaseId = skuBaseId.split(',');
      if (skuBaseId.slice(-1) === ',') {
        args.skuBaseId.pop();
      }
    } else {
      args.skuBaseId = skuBaseId;
    }
    var quantity = $addBtn.attr('data-qty');
    if (!!quantity) {
      args.quantity = quantity;
    } else {
      args.INCREMENT = 1;
    }

    // Replenishment updates when sku is refillable and enable_replenishment=true in config.
    //  (currently applicable only when adding one item at a time)
    // Skip for Dramming product as it allows more than one SkU at a time
    var kitType = $addBtn.data('kit-type');
    var transKitType = site.translations.product.spp_dramming_kit_type;
    var kitPatt = new RegExp(transKitType, 'g');
    var skuDataL2 = prodcat.data.getSku(args.skuBaseId);

    if (skuDataL2 && _.isString(args.skuBaseId) && Drupal.settings.product_display && Drupal.settings.product_display.has_replenishment && !kitPatt.test(kitType)) {
      if (skuDataL2.REFILLABLE) {
        var frequency = $addBtn.data('replenishment');
        if (!!frequency) {
          args.REPLENISHMENT_FREQ = frequency;
        } else {
          args.REPLENISHMENT_FREQ = 0;
        }
        args.action = 'add';
        args.itemType = 'replenishment';
        args.add_to_cart = 1;
      }
    }

    var kitName = $addBtn.data('kit-name');
    if (!!kitName) {
      kitName = kitName + ' ';
    } else {
      kitName = Drupal.settings.default_kit_name;
    }

    var isKit = !!kitSkus;
    if (isKit) {
      args.COLLECTION_TYPE = 'UKIT';
      args.CAT_BASE_ID = $addBtn.data('kit-cat');
      args.COLLECTION_SUBTYPE = kitType;
      args.COLLECTION_NAME = kitName + Math.floor(Math.random() * 10000);
      args.SKU_BASE_ID = String(kitSkus).split(',');
      args.QTY = 1;
      args.action = 'add,edit,create_kit';
      args._SUBMIT = 'alter_collection';
      args.HAS_MULTIPLE_SKUS = 1;
      if ($.cookie('csrftoken')) {
        args._TOKEN = $.cookie('csrftoken');
      }

      delete args.skuBaseId;
      generic.jsonrpc.fetch({
        method: 'rpc.form',
        params: [args],
        onSuccess: function(r) {
          var resultData = r.getData();
          var messages = r.getMessages();
          if (messages) {
            messages.forEach(function(msg) {
              if (msg.key === 'offer_criteria_not_met' && msg.tags.indexOf('one_time_purchase') > -1) {
                resultData.otpErrorMessage = msg.text;
              }
            });
          }
          $(document).trigger('addToCart.buttonStatus', [$addBtn, 'success']);
          $(document).trigger('addToCart.success', [resultData]);
          generic.overlay.hide();
        },
        onFailure: function(ss) {
          var errorObjectsArray = ss.getMessagesError();
          $(document).trigger('addToCart.buttonStatus', [$addBtn, 'failure']);
          $(document).trigger('addToCart.failure', [errorObjectsArray]);
        }
      });
      return;
    }
  });

  $(document).on('click', '.js-add-to-cart, .js-add-to-bag', function(e) {
    var skuInvStatus;
    e.preventDefault();

    var $addBtn = $(this);

    var skuBaseId = $addBtn.data('sku-base-id');
    if (!skuBaseId || skuBaseId.length < 1) {
      return;
    }

    var skuDataL2;

    // Waitlist / Notify Me
    if (Drupal.settings.common && !!Drupal.settings.common.has_waitlist) {
      skuDataL2 = prodcat.data.getSku(skuBaseId);
      skuInvStatus = parseInt(skuDataL2.INVENTORY_STATUS, 10);
      if ((!!Drupal.settings.common.display_notify_tos && skuInvStatus === 2) || (!!Drupal.settings.common.display_notify_cs && skuInvStatus === 3)) {
        $(document).trigger('product.waitlist.overlay', skuDataL2);
        return;
      }
    }

    if ($addBtn.is('[disabled]')) {
      return;
    }

    // Trigger the button toggle event to show loading message until all this code and RPC call are complete.
    $(document).trigger('addToCart.toggle', [$addBtn]);

    // Account for adding multiple skus to bag
    var args = {};
    skuBaseId = String(skuBaseId);
    if (skuBaseId.indexOf(',') >= 0) {
      // clean the string
      skuBaseId = skuBaseId.replace(/(\s|\r\n|\n|\r)/gm, '');
      args.skuBaseId = skuBaseId.split(',');
      if (skuBaseId.slice(-1) === ',') {
        args.skuBaseId.pop();
      }
    } else {
      args.skuBaseId = skuBaseId;
    }

    var quantity = $addBtn.data('qty');
    if (!!quantity) {
      args.quantity = quantity;
    }

    // Replenishment updates when sku is refillable and enable_replenishment=true in config.
    //  (currently applicable only when adding one item at a time)
    if (_.isString(args.skuBaseId) && Drupal.settings.common && Drupal.settings.common.has_replenishment) {
      skuDataL2 = prodcat.data.getSku(args.skuBaseId);
      if (skuDataL2.REFILLABLE) {
        var frequency = $addBtn.attr('data-replenishment');
        if (!!frequency) {
          args.REPLENISHMENT_FREQ = frequency;
        } else {
          args.REPLENISHMENT_FREQ = 0;
        }
        args.action = 'add';
        args.itemType = 'replenishment';
        args.add_to_cart = 1;
      }
    }

    args.$addBtn = $addBtn;

    prodcat.ui.addToCart(args);
  });

  /*
     ***********************
     * Add-to-favorites button
     ***********************
     */
  var tooltipsterDefaultSettings = {
    animation: 'fade',
    arrow: false,
    contentAsHTML: true,
    interactive: true,
    multiple: true,
    onlyOne: true,
    position: 'top',
    restoration: 'none',
    speed: 500,
    theme: 'tooltipster-smashbox',
    timer: 3000,
    trigger: 'click',
    updateAnimation: false
  };

  $(document).on('product.initFavoritesTooltip', function(e, $product) {
    //console.log('product.initFavoritesTooltip');
    var $faveLink = $product.find('.js-add-to-favorites');
    $faveLink.tooltipster(tooltipsterDefaultSettings);
  });

  $(document).on('click', '.js-add-to-favorites', function(e) {
    e.preventDefault();
    var $this = $(this);
    var skuBaseId = $this.data('sku-base-id');
    var $icon = $this.find('.icon');

    $this.tooltipster(tooltipsterDefaultSettings);
    $this.tooltipster('disable').tooltipster('hide', function() {
      if ($icon.hasClass('icon--heart--selected')) {
        $this.tooltipster('content', $this.data('tooltip-removed'));
        prodcat.ui.addToFavorites({skuBaseId: skuBaseId, $el: $this, action: 'delete'});
      } else {
        $this.tooltipster('content', $this.data('tooltip-success'));
        prodcat.ui.addToFavorites({skuBaseId: skuBaseId, $el: $this, action: 'add'});
      }
    });
  });

  $(document).on('product.skuDisplay', '.js-product', function(e, skuBaseId) {
    //console.log('product.skuDisplay');
    $(this).trigger('product.updateText', [skuBaseId]);
    $(this).trigger('product.updateShadeRoute', [skuBaseId]);
    $(this).trigger('product.updateLargeImage', [skuBaseId]);
    $(this).trigger('product.updateLargeBadge', [skuBaseId]);
  });

  /*
     ***********************
     * display text fields (price, shade name, etc)
     ***********************
     */
  $(document).on('product.updateText', '.js-product', function(e, skuBaseId) {
    //console.log('product.updateText');
    var $product = $(this);
    var skuData = prodcat.data.getSku(skuBaseId);
    var textFields = [
      {selector: 'js-product-size', field: 'PRODUCT_SIZE'},
      {selector: 'js-product-price', field: 'formattedPrice'},
      {selector: 'js-sku-shade-name', field: 'SHADENAME'},
      {selector: 'js-sku-shade-description', field: 'SHADE_DESCRIPTION'}
    ];

    _.each(textFields, function(el) {
      var $el = $('.' + el.selector, $product);
      if ($el.length < 1) {
        return;
      }
      $el.html(skuData[el.field]);
    });
  });

  // set Sale price after Inventory Status L2 call
  $(document).on('product.setSalePrice', '.js-product', function(e, skuDataL2) {
    //console.log('product.setSalePrice');
    var $product = $(this);
    var is_pro = parseInt(site.userInfoCookie.getValue('is_pro')) === 1;

    if (!!skuDataL2.PRICE2 && !is_pro) {
      var $price = $('.js-sku-price', $product);
      $price.html('<span class="original-price js-original-price">' + skuDataL2.formattedPrice2 + '</span> ' + '<span class="sale-price js-sale-price">' + skuDataL2.formattedPrice + '</span>');
    }

    if (is_pro) {
      $product.trigger('product.resetSalePrice');
    }
  });

  // reset Sale price for Pro users
  $(document).on('product.resetSalePrice', '.js-product', function() {
    //console.log('product.resetSalePrice');
    var $product = $(this);
    var $price = $('.js-sku-price, .js-product-price, .esearch-product__price', $product);
    if ($price.find('.sale-price').length > 0) {
      $price.find('.sale-price').remove();
      $product.find('.product-brief__sale').remove();
      $price.find('.original-price').contents().unwrap();
    }
  });

  $(document).on('user.loaded', function(e, val) {
    //console.log('user.loaded');
    if (!!val.is_pro) {
      var $product = $('.js-product');
      $product.trigger('product.resetSalePrice');
    }
  });

  $(document).on('endeca.search.results.loaded endeca.typeahead.complete', function() {
    //console.log('endeca.search.results.loaded endeca.typeahead.complete');
    var $product = $('.js-product');
    var is_pro = parseInt(site.userInfoCookie.getValue('is_pro')) === 1;

    if (is_pro) {
      $product.trigger('product.resetSalePrice');
    }
  });

  $(document).on('product.favorites', function(e, $el, action) {
    //console.log('product.favorites', action);
    var $icon = $el.find('.icon');

    if (action === 'success') {
      $icon.removeClass('icon--heart').addClass('icon--heart--selected');
      $el.tooltipster('enable').tooltipster('show');
    }

    if (action === 'removed') {
      $icon.removeClass('icon--heart--selected').addClass('icon--heart');
      $el.tooltipster('enable').tooltipster('show');
    }
  });

  $(document).on('addToCart.failure addToWishlist.failure', function(event, errorObjectsArray) {
    // Escape any html in the alert box.
    var prodAddedMsg = $('<div/>').html(errorObjectsArray[0].text).text();
    generic.overlay.launch({
      content: prodAddedMsg,
      includeBackground: true,
      includeCloseLink: true,
      initialHeight: 0,
      height: 0,
      width: '50%',
      onComplete: function() {
        $(this).colorbox.resize();
      }
    });
  });

  /**
     * Toggles the visibility of an add-to button, and its sibling loading message
     ***/
  $(document).on('addToCart.toggle', function(event, $addBtn) {
    if (!$addBtn || $addBtn.length < 1) {
      return;
    }

    var loadingDiv = $addBtn.siblings('.js-loading-message');
    var successDiv = $addBtn.siblings('.js-success-message');

    if (!$addBtn.hasClass('hidden')) { // on first toggle:
      $addBtn.addClass('hidden'); // - hide add button
      loadingDiv.removeClass('hidden'); // - show loading message
    } else { // on next toggle (success):
      loadingDiv.addClass('hidden'); // - hide loading message
      successDiv.removeClass('hidden'); // - show success message
      setTimeout(function() { // after timeout:
        successDiv.addClass('hidden'); // - hide success message
        $addBtn.removeClass('hidden'); // - show add button
      }, 2000);
    }
  });

  $(document).on('product.updateShadeRoute', '.js-product', function(e, skuBaseId) {
    //console.log('product.updateShadeRoute');
    var $product = $(this);
    var skuData = prodcat.data.getSku(skuBaseId);
    var shadeName = _.result(skuData, 'SHADENAME');

    // include reserved characters missing from encodeURIComponent()
    function _fixedEncodeURIComponent(str) {
      return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
      });
    }

    if (!!shadeName) {
      var prodData = prodcat.data.getProduct(skuData.PRODUCT_ID);
      var $sppLinks = $('.js-spp-link', $product);
      shadeNameNoSpaces = _fixedEncodeURIComponent(shadeName.toString().split(' ').join('_'));

      $sppLinks.each(function() {
        $(this).attr('href', prodData.url + '#/shade/' + shadeNameNoSpaces);
      });

      if ($product.hasClass('product-full')) {
        var shadeNameWithQueryString = window.location.hash;
        if (shadeNameWithQueryString.indexOf('?') >= 0) {
          shadeNameNoSpaces += '?' + shadeNameWithQueryString.split('?')[1];
        }
        history.pushState({}, shadeName, '#/shade/' + shadeNameNoSpaces);
      }
    }
  });

  /**
     * Wire up behavior on page-load according to js- classnames.
     */
  var shadeNameNoSpaces;
  Drupal.behaviors.prodcatUiInit = {
    attach: function(context) {
      var $product = $('.js-product', context);
      $product.trigger('product.init');
      $(context).trigger('products.init-start');
    }
  };

  // hijack back button instead of going through every shade that was selected
  if (document.referrer.length > 0) {
    // Necessary hack because WebKit fires a popstate event on window load
    // https://code.google.com/p/chromium/issues/detail?id=63040
    // https://bugs.webkit.org/process_bug.cgi
    window.addEventListener('load', function() {
      setTimeout(function() {
        window.addEventListener('popstate', function(e) {
          // send the user back to the referrer when the user has been clicking shades on SPP and wants to go back
          if (e.target.location.hash.match(/^#\/shade/)) {
            window.location = document.referrer;
          }
        });
      }, 0);
    });
  }

  /*
     ***********************
     * swap large image when a sku is selected
     ***********************
     */
  $(document).on('product.updateLargeImage', '.js-product', function(e, skuBaseId) {
    var slickSlidersImages = [
      {selector: '.js-product-large-thumb-image:not(.modiface-thumbnail-img)', field: 'LARGE_IMAGE'},
      {selector: '.js-product-large-image:not(.spp-add-bag-bar__image)', field: 'LARGE_IMAGE'}
    ];
    var skuData = prodcat.data.getSku(skuBaseId);
    var $productEl = $(this);
    _.each(slickSlidersImages, function(el) {
      var $el = $(el.selector, $productEl);
      if ($el.length < 1) {
        return;
      }
      var imageMock = $el[0];
      var parentMock = $($el[0]).parent();
      parentMock.removeClass('slick-cloned');
      var $sliderParent = parentMock.parent();
      var $slider = $sliderParent.closest('.slick-slider') || null;
      if ($slider.length) {
        $slider.css({'visibility': 'hidden'});
      }
      var currentSlide = $slider.data('currentSlickIndex') || 0;
      var imageField = el.field;
      var availableParents = [];
      var newImagesCount = skuData[imageField].length;
      var imageParent = $();
      $.each($el, function(c, img) {
        // we need to remove the old images, we can't just swap them as each sku can have any number of large images
        // but we keep the parents if there are more new images as we need to keep the badge html in the parent html
        if (c < newImagesCount) {
          availableParents.push($(img).parent().removeClass('slick-cloned'));
          $(img).remove();
        } else {
          $(img).parent().remove();
        }
      });
      var image = [];
      var imageParents = [];
      $.each(skuData[imageField], function(i, el) {
        if (el !== '') {
          image[i] = $(imageMock).clone(true, true);
          image[i].attr('src', el);
          image[i].attr('data-zoom-image', el);
          if (i < availableParents.length) {
            imageParent = availableParents[i];
            if (i !== 0) {
              // we keep the badge only on the first image from the sku
              imageParent.find('.js-product-large-image-badge').remove();
            }
          } else {
            imageParent = parentMock.clone(true, true).empty();
          }
          if (i > 0) {
            imageParent.removeClass('slick-current');
            imageParent.removeClass('slick-active');
            imageParent.attr('aria-hidden', true);
            imageParent.attr('hidden', true);
            imageParent.hide();
          }
          image[i].appendTo(imageParent);
          imageParents.push(imageParent);
        }
      });
      $('.slick-cloned', $sliderParent[0]).remove();
      $.when($sliderParent.prepend(imageParents)).done().then(reinitGallery($sliderParent, $slider, currentSlide, $productEl));
    });

    // Loop to change the images from spp-add-bar
    var sppAddBarImage = [
      {selector: '.js-product-large-image.spp-add-bag-bar__image', field: 'LARGE_IMAGE'}
    ];
    skuData = prodcat.data.getSku(skuBaseId);
    $productEl = $(this);

    _.each(sppAddBarImage, function(el) {
      var $el = $(el.selector, $productEl);
      if ($el.length < 1) {
        return;
      }
      if (skuData[el.field] && skuData[el.field].length) {
        $el[0].setAttribute('src', skuData[el.field][0]);
      } else {
        $el.attr('src', skuData[el.field]);
        $el.data('zoom-image', skuData[el.field]);
      }
      return true;
    });
  });
  function reinitGallery($sliderParent, $slider, currentSlide, $productEl) {
    setTimeout(function() {
      // on page load the slider is not initialized so we have to load it here to get it
      $slider = $slider.length ? $slider : $sliderParent.closest('.slick-slider');
      if ($slider !== null && typeof $slider[0] !== 'undefined') {
        var slidesThumbs = $sliderParent.find('.js-spp-carousel__thumbnail:not(.slick-cloned)');
        var slidesLarge = $sliderParent.find('.js-spp-carousel__slide:not(.slick-cloned)');
        var $thumbCarousel = $('.js-spp-carousel-thumbs', $productEl);
        var slick = $slider[0].slick;
        var maxSlideIndex;
        if (slidesThumbs.length > 0) {
          maxSlideIndex = slidesThumbs.length - 1;
          slick.$slides = slidesThumbs;
          if (currentSlide > maxSlideIndex || slick.options.slidesToShow > maxSlideIndex) {
            // since the sku's can have different number of images, we need to make sure
            // the previous index is not hiegher then the last available after the reinit
            // + set currentSlide to 0 is mandatory if there are less then 5 thumbs avaialable
            // to keep all the slides in view.
            currentSlide = 0;
          }
          slidesThumbs.once('thumbs-focus').on('focus', function(e) {
            currentSlide = $(e.target).data('slickIndex');
            $slider.data('currentSlickIndex', currentSlide).attr('data-current-slick-index', currentSlide);
          });

          slick.currentSlide = currentSlide;
          slick.refresh(currentSlide);
          slidesThumbs.show();
          $slider.css({'visibility': 'visible'});
          $thumbCarousel.slick('slickGoTo', parseInt(currentSlide, 10));
          $('.js-spp-carousel__thumbnail.slick-cloned').show();
        } else if (slidesLarge.length > 0) {
          maxSlideIndex = slidesLarge.length - 1;
          $slider.on('afterChange', function(e, slick, currentIndex) {
            $slider.data('currentSlickIndex', currentIndex).attr('data-current-slick-index', currentIndex);
            $thumbCarousel.data('currentSlickIndex', currentIndex).attr('data-current-slick-index', currentIndex);
          });
          slick.$slides = slidesLarge;
          if (currentSlide > maxSlideIndex) {
            // since the sku's can have different number of images, we need to make sure
            // the previous index is not hiegher then the last available after the reinit
            currentSlide = 0;
          }
          slick.currentSlide = currentSlide;
          slick.refresh();
          slidesLarge.show();
          $slider.css({'visibility': 'visible'}).promise().done().then(function() {
          // trigger productHeightInfo to make sure the description has the correct height
          // after the image swaps fix for CX-8910
            $(document).trigger('productHeightInfo').data('productInfoHeight');
            if (!site.client.isMobile) {
              $(window).trigger('reinitImageGallery');
            }
          });
          // trigger productHeightInfo to make sure the description has the correct height
          // after the image swaps fix for CX-8910
        }
      } else if ($('.product-full__image--single').length) {
        if (!site.client.isMobile) {
          $(window).trigger('reinitImageGallery');
        }
      }
    }, 700);
  }

  /*
     ***********************
     * swap large MISC_FLAG badge image when a sku is selected
     ***********************
     */
  $(document).on('product.updateLargeBadge', '.js-product', function(e, skuBaseId) {
    var badgeField = [
      {selector: '.js-product-large-image-badge', field: 'BADGE_LARGE'}
    ];
    var skuData = prodcat.data.getSku(skuBaseId);
    var $productEl = $(this);

    _.each(badgeField, function(el) {
      var $el = $(el.selector, $productEl);
      if ($el.length < 1) {
        return;
      }
      if (!skuData[el.field]) {
        $el.attr('src', '').addClass('hidden');
      } else {
        $el.attr('src', skuData[el.field]).removeClass('hidden');
        var $zoomableImage;
        _.each($el, function(badge) {
          $(badge).on('mouseover', function(event) {
            $zoomableImage = $(badge).next('.js-product-full-zoomable').first();
            $('.zoomContainer.hidden').removeClass('hidden');
            $zoomableImage.trigger('mouseover', event.data);
          }).on('mousemove', function(event) {
            $zoomableImage.trigger('mousemove', event.data);
          });
        });
      }
    });
  });
})(jQuery, window.generic = window.generic || {});

(function($) {
  Drupal.behaviors.selectBox = {
    /**
     * Flag to ensure this JS is only attached once.
     * @type {boolean}
     */
    attached: false,

    /**
     * Builds a replacement out of div elements which are more
     * easily style-able. All attributes are copied over.
     * Sets up two data attributes so the old and new can reference
     * each other.
     * @param {Object} $oldSelect - old select element.
     */
    replace: function($oldSelect) {
      // Doublecheck if the old select element has already been replaced,
      // and remove the replacement dropdown to allow a refresh.
      if ($oldSelect.hasClass('js-select-box-replaced')) {
        $oldSelect.data('new-select-box-reference').remove();
        $oldSelect.removeClass('select-box--replaced js-select-box-replaced');
        $oldSelect.removeData('new-select-box-reference');
      }

      var $newSelect      = $('<div></div>');
      var $newLabel       = $('<div></div>');
      var $newOptions     = $('<div></div>');

      // Copy all attributes from old select element.
      var attributes = $oldSelect.prop('attributes');
      $.each(attributes, function() {
        if (this.name === 'disabled') {
          $newSelect.addClass('select-box--disabled js-select-box-disabled');
        } else {
          $newSelect.attr(this.name, this.value);
        }
      });

      // Add BEM and JS classes to the new select and its label.
      $newSelect.addClass('select-box js-select-box');
      $newLabel.addClass('select-box__label js-select-box-label');
      $newSelect.append($newLabel);

      // Copy over all the old select's options.
      $oldSelect.children('option').each(function() {
        var $oldOption = $(this);
        var $newOption = $('<div></div>');
        var attributes = $oldOption.prop('attributes');

        $.each(attributes, function() {
          $newOption.attr(this.name, this.value);
        });

        $newOption.text($oldOption.text());
        $newOption.addClass('select-box__option js-select-box-option');
        $newOptions.append($newOption);
      });

      // Add BEM and JS classes to the new select's options div.
      $newOptions.addClass('select-box__options js-select-box-options');
      $newSelect.append($newOptions);

      // Put the new select into the DOM.
      $oldSelect.before($newSelect);
      $oldSelect.addClass('select-box--replaced js-select-box-replaced');

      // Store each other's references for later use.
      $oldSelect.data('new-select-box-reference', $newSelect);
      $newSelect.data('old-select-box-reference', $oldSelect);
    },

    /**
     * Takes the currently selected option of old select and
     * shows it in the label area of the new select box.
     * If there is no option by default, then it loads the first option.
     * @param {Object} $oldSelect - old select element.
     */
    setLabel: function($oldSelect) {
      var selectedText = $oldSelect.children('option:selected').text() || $oldSelect.children('option').first().text();
      var $newSelect = $oldSelect.data('new-select-box-reference');
      if (!!$newSelect) {
        $newSelect.children('.js-select-box-label').text(selectedText);
      }
    },

    /**
     * Toggles the new select box's options div dropdown.
     * @param {Object} $newSelect - replacement .js-select-box div element.
     */
    toggleOptions: function($newSelect) {
      if (!$newSelect.hasClass('js-select-box-disabled')) {
        $newSelect.children('.js-select-box-options').toggleClass('open');
        $newSelect.children('.js-select-box-label').toggleClass('open');
      }
    },

    /**
     * Similar to toggleOptions but strictly closes the div dropdown.
     * @param {Object} $newSelect - replacement .js-select-box div element.
     */
    closeOptions: function($newSelect) {
      $newSelect.children('.js-select-box-options').removeClass('open');
      $newSelect.children('.js-select-box-label').removeClass('open');
    },

    /**
     * Updates the hidden select element with the matching option
     * according to what was clicked in the new select box. Then triggers
     * the appropriate event on the old select element.
     * @param {Object} $option - one of the new js-select-box-option div elements.
     */
    updateOldSelect: function($option) {
      var $newSelect = $option.parents('.js-select-box');
      var $oldSelect = $newSelect.data('old-select-box-reference');

      $oldSelect.children('option').each(function() {
        if ($option.html().trim() === this.innerHTML.trim()) {
          $oldSelect.val(this.value);
        }
      });

      $oldSelect.trigger('change');
    },

    /*
     * Refreshes the new select box with up-to-date markup
     * from the old select element.
     * @param {Object} $newSelect - replacement .js-select-box div element.
     */
    refresh: function($newSelect) {
      var $oldSelect = $newSelect.siblings('select');
      this.replace($oldSelect);
    },

    /**
     * Sets up events for controlling the select box.
     * @type {Object} $oldSelect - the replaced select element.
     */
    setEvents: function($oldSelect) {
      var self = this;
      var $newSelect = $oldSelect.data('new-select-box-reference');

      // Toggle visibility of dropdown.
      $newSelect.off('click').on('click', function(event) {
        event.stopPropagation();

        // Close all the other select dropdowns.
        $('.js-select-box').not(this).each(function() {
          self.closeOptions($(this));
        });

        // Toggle open the particular select that was clicked.
        self.toggleOptions($(this));
      });

      // Close the dropdown options when clicked outside of the select.
      $newSelect.off('clickoutside').on('clickoutside', function() {
        self.closeOptions($(this));
      });

      // When an option is selected, put the value of the
      // old hidden select and update the label.
      $('.js-select-box-option').off('click').on('click', function() {
        var $this = $(this);
        var $oldSelect = $this.parents('.js-select-box').data('old-select-box-reference');
        self.updateOldSelect($this);
        self.setLabel($oldSelect);
      });

      // Update the select box label when a sku is selected.
      $(document).on('product.skuSelect', function(e, skuBaseId) {
        self.setLabel($oldSelect);
      });

      // Only set this event if the $oldSelect is a sku select menu.
      // Update both the <select> and its replacement. This is used
      // for shade routing on SPP page load.
      if ($oldSelect.hasClass('js-sku-menu')) {
        $(document).on('product.initSelects', '.js-product', function(e, skuBaseId) {
          $oldSelect.val(skuBaseId);
          self.setLabel($oldSelect);
        });
      }
    },

    /**
     *  Sets up events related to selects that may not be on the page at first.
     */
    setRefreshEvents: function() {
      var self = this;

      // On quickshop load, call the appropriate shade picker setup methods.
      $(document).on('product.quickshop.launch.inline product.quickshop.launch.inlineCarousel product.quickshop.launch.overlay', function() {
        self.initAll($(this));
      });

      // On re-rendering of panels in checkout, replace the select elements on the page.
      $(document).on('checkout.panels.rendered', function() {
        self.initAll();
      });

      // On refreshing any address forms in checkout, replace the select elements on the page.
      $(document).on('checkout.addressForm.refreshed', function() {
        self.initAll();
      });

      // On disable of a select element, refresh the dropdown to apply the disable property.
      $('select').on('select.disabled', function() {
        self.initOne($(this));
      });

      // On enable, of a select element, refresh the dropdown too.
      $('select').on('select.enabled', function() {
        self.initOne($(this));
      });
      
      // On refreshing any address forms in account, replace the select elements on the page.
      $(document).on('account.addressForm.refreshed', function() {
        self.initAll();
      });

      // After adding the loyalty points panel to the account landing page.
      $('section').on('account.loyaltyPointsPanel.loaded', function() {
        self.initAll();
      });

      $(document).on('user.loaded', function() {
        self.initAll();
      });

      // After a form, particularly in checkout, has gone through validation.
      $(document).on('form.validated', function() {
        self.initAll();
      });

      // Generic event to trigger all selects to be initialized.
      $(document).on('selectBox.refresh', function() {
        self.initAll();
      });
    },

    /*
     * Little setup method for replacing one particular select element.
     * param {Object} oldSelect - the particular select element
     */
    initOne: function($oldSelect) {
      this.replace($oldSelect);
      this.setLabel($oldSelect);
      this.setEvents($oldSelect);
    },

    /*
     * Little setup method for replacing all select elements in a given context.
     * @param {Object} context - the DOM scope, usually document.
     */
    initAll: function(context) {
      // Turns out IE didn't like $(document) in the default parameter definition
      // so its been moved to inside the method.
      if ((typeof context === 'undefined') || (context === null)) {
        context = $(document);
      }

      var self = this;

      $('select', context).each(function() {
        var $oldSelect = $(this);
        self.initOne($oldSelect);
      });
    },

    /**
     * Standard method for attaching this JS.
     * @param {Object} context - the DOM scope, usually document.
     * @param {Object} settings - useful collection of site info.
     */
    attach: function(context, settings) {
      var self = this;

      // Only replace select boxes on desktop.
      if (!self.attached && !site.client.isMobile) {
        self.initAll(context);
        self.setRefreshEvents();
      }
      self.attached = true;
    }
  };
})(jQuery);

var generic = generic || {};

(function($) {
  'use strict';

  var turnRelativeToAbsolute = function(urlToFix) {
    if (urlToFix.substr(0,1) === '/') {
        urlToFix = window.location.origin + urlToFix;
    }
    return urlToFix;
  };

  var applySocialMediaOffer = function(offerCode) {
    site.userInfoCookie = site.userInfoCookie || {};
    site.userInfoCookie.getValue = site.userInfoCookie.getValue || function() { return ''; };

    var signedIn = parseInt( site.userInfoCookie.getValue('signed_in') );
    var isLoyaltyMember = parseInt( site.userInfoCookie.getValue('is_loyalty_member') );

  // if the user is signed in and a loyalty member apply the social media offer
    if (signedIn && isLoyaltyMember && offerCode) {
        var paramObj = {
          'offer_code' : offerCode,
          'do_not_defer_messages' : 1
        };

        generic.jsonrpc.fetch({
          method: 'offers.apply',
          params: [paramObj]
        });
    }
  };

  var setupShareLinks = function() {
    // setup share links
    var $shareLinks = $('.js-share');
    $shareLinks.each(function() {
      var $link = $(this);
      var href = $link.attr('href');
      if (!$link.data('href')) {
        $link.data('href', href);
        if ($link.parents('.js-quickshop').length) {
          href = $link.parents('.js-quickshop').find('.js-spp-link').attr('href');
          href = $link.data('href') + turnRelativeToAbsolute(href);
        } else {
          href = $link.data('href') + window.location.href;
        }
      }

      if ($link.hasClass('js-share--facebook')) {
        $link.attr('offer_code', 'lyl_fb10');
      }

      if ($link.hasClass('js-share--twitter')) {
        $link.attr('offer_code', 'lyl_tw10');
      }

      if ($link.hasClass('js-share--pinterest')) {
        $link.attr('offer_code', 'lyl_pt20');

        // Add default media for Pinterest
        if (!$link.data('media')) {
          var mediaURL = '';
          if ($link.parents('.js-quickshop').length) {
            mediaURL = $link.parents('.js-quickshop').find('.js-product-large-image').attr('src');
          } else {
            mediaURL = $('.js-product-image').first().attr('src');
          }
          mediaURL = turnRelativeToAbsolute(mediaURL);
          $link.data('media', mediaURL);
          href += '&media=' + $link.data('media');
        }
      }

      $link.attr('href', href);
    });

    $('.js-share--share-email').on('click', function(e) {
      e.preventDefault();
      // Trigger Social Share overlay template with Product ID as input
      var data = {};
      data.product_id = $(this).attr('data-productid');
      $(document).trigger('product:social_share:overlay', data);
    });
  };

  $(document).on('product.quickshop.launch.inline product.quickshop.launch.inlineCarousel product.quickshop.launch.overlay', function() {
    setupShareLinks();
  });

  $(document).on('product.updateShadeRoute', '.js-product', function(e, skuBaseId) {
    var $shareLinks = $('.js-share');
    $shareLinks.each(function() {
      var $link = $(this);
      var href = $link.attr('href');
      if (!!$(this).data('href')) {
        if ($link.parents('.js-quickshop').length) {
          href = $link.parents('.js-quickshop').find('.js-spp-link').attr('href');
          href = $link.data('href') + turnRelativeToAbsolute(href);
        } else {
          href = $link.data('href') + window.location.href;
        }

        if (!!$link.data('media')) {
          var mediaURL = $link.data('media');
          if ($link.parents('.js-quickshop').length) {
            mediaURL = $link.parents('.js-quickshop').find('.js-product-large-image').attr('src');
          } else if ($link.parents('.product-full').find('.js-spp-carousel').length) {
            mediaURL = $('.js-product-image').eq(1).attr('src');
          } else {
            mediaURL = $('.js-product-image').first().attr('src');
          }
          mediaURL = turnRelativeToAbsolute(mediaURL);
          $link.data('media', mediaURL);
          href += '&media=' + $(this).data('media');
        }
      }
      $link.attr('href', href);
    });
  });

  $(document).on('click', '.js-share', function(event) {
    var width = 600;
    var height = 350;
    var openUrlShare = $(this).attr('href');
    var openUrl = openUrlShare.replace(/#/g, "%23");

    // Allow for borders.
    var leftPosition = (window.screen.width / 2) - ((width / 2) + 10);
    // Allow for title and status bars.
    var topPosition = (window.screen.height / 2) - ((height / 2) + 50);
    var windowFeatures = "status=no,height=" + height + ",width=" + width;
    windowFeatures += ",resizable=yes,left=" + leftPosition + ",top=" + topPosition;
    windowFeatures +=  ",screenX=" + leftPosition + ",screenY=" + topPosition;
    windowFeatures += ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";

    window.open(openUrl,'sharer', windowFeatures);
    applySocialMediaOffer($(this).attr('offer_code'));

    event.preventDefault();
  });

  $(function() {
    setupShareLinks();
  });

})(jQuery);

window.site = window.site || {};

site.template = (function($, _, Mustache) {
  site.templates = site.templates || {};
  site.translations = site.translations || {};

  var defaults = {
    globals: {
      t: site.translations,
      variables: {
        // IE doesn't support location.origin, so...
        site_url: window.location.protocol + '//' + window.location.hostname
      }
    }
  };

  // include config settings from brand common module
  if (!_.isUndefined(Drupal) && !_.isUndefined(Drupal.settings) && !_.isUndefined(Drupal.settings.common)) {
    $.extend(defaults.globals.variables, Drupal.settings.common);
  }

  var public = {
    get: function(args) {
      var template = site.templates[args.name];

      // If that didn't work, search for a versioned match of the same template
      // (eg. template_v2)
      if (!template && args.name) {
        for (var key in site.templates) {
          if (site.templates.hasOwnProperty(key)) {
            var matcher = new RegExp(args.name + "_v(\\d+)$");
            if (matcher.test(key)) {
              template = site.templates[key];
              break;
            }
          }
        }
      }

      if (_.isUndefined(template)) {
        console.log('The template ' + args.name + ' cannot be found');
      }

      var rendered = this.render(template, args.data);

      if (_.isFunction(args.callback)) {
        var so = args.callback(rendered);
        if (!_.isUndefined(so)) {
          return so;
        }
      }

      return rendered;
    },

    render: function(template, data) {
      defaults.globals.t = site.translations;
      data = data || {};

      // You can pass just the template as a string if you want:
      if (_.isString(template)) {
        template = {
          content: template,
          data: {}
        };
      }

      var view = $.extend({}, defaults, template.data, data);
      var partials = {};

      if (!_.isUndefined(template.partials)) {
        $.each(template.partials, function(key, name) {
          if (_.isUndefined(site.templates[key]) && _.isUndefined(site.templates[name])) {
            console.log('The partial ' + key + ' or ' + name + ' cannot be found');
          }

          var pkey = (!_.isUndefined(site.templates[key])) ? key : name;
          partials[pkey] = site.templates[pkey].content;
        });
      }

      return Mustache.render(template.content, view, partials);
    }
  };

  return public;
})(
  window.jQuery = window.jQuery || function(){},
  window._ = window._ || {},
  window.Mustache = window.Mustache || {}
);

(function($, generic, site) {

/**
  * Method to grab a cookie and use that to control DOM elements as needed.
  * Handles the setting and getting of the user cookie defined in cookie.name and set in backend.
  * To find where the cookie is set on backend, look to Request::TransactionLocaleHandler.
  * Example cookie structure not signed in:
  *   FE_USER_CART=item_count:1&first_name:&signed_in:0&region_id:0
  * Example cookie structure signed in:
  *   FE_USER_CART=item_count:3&first_name:John&signed_in:1&region_id:0
  * You can set specific functions on page load using events.load or hook into javascript events
  *  by defining them in the events class and adding the event to events.init.
  * The cookie class is used to handle all the cookie functionality such as the setting and getting.
  * This method is meant to be stand alone so should be able to add to a brand without many tweaks.
  * Uses straight javascript so not dependent on a javascript framework except for DOM load.
  * Preferably added to the where ever the globalnav javascript is added within a brand.
  * @memberOf site
*/
site.userInfoCookie = function() {
  // Set global vars here.
  var nodes = {};

  // Private internal cookie class.
  // Leverages generic.cookie to get and set the cookie values.
  var cookie = {
    name: 'FE_USER_CART',
    value: '',
    regEx: function(key) {
      if (!key) {
        return null;
      }
      return new RegExp(key + ':([^;&,}]*)');
    },
    set: function() {
      if (!this.name) {
        return null;
      }
      var userCookie = generic.cookie(this.name);
      this.value = userCookie ? userCookie : '';
    },
    getValue: function(key) {
      var keyVal = this.value.match(this.regEx(key));
      return keyVal ? (keyVal[1] ? keyVal[1] : null) : null;
    },
    setValue: function(key, val) {
      var match  = this.value.match(this.regEx(key));
      var oldValue = match[0];
      var newValue = this.value.replace(match[1], val);
      generic.cookie(this.name, newValue, { path: '/' });
      this.value   = newValue;
    }
  };

  // Private events class that handles all individual events.
  // Add all events in 'init' method so they will get fired on page load.
  // The cart event is commented out but there as an example.
  var events = {
    init: function() {
      this.load();
      //this.cart();
    },
    load: function() {
      _setCartItemsTotal();
    },
    cart: function() {
      /*
      $(document).on('cart:countsUpdated', function() {
        var cartCount = generic.checkout.cart.getTotalItems();
        cookie.setValue('item_count', cartCount);
      });
      */
    }
  };

  /* Additional helper functions below here. */

  // Pulls in the cookie info and updates the DOM;
  var _setCartItemsTotal = function() {
    if (!nodes.cartTotalContainer) {
      return null;
    }

    var valueKey = 'item_count';
    var itemsTotal = cookie.getValue(valueKey) - 0 || 0;
    $.each(nodes.cartTotalContainer, function() {
      $(this).html(itemsTotal);
    });
  };

  // BRAND SPECIFIC: Get any DOM nodes and assign them to the global class var nodes. Varies between brands.
  // Helps keep all the brand specific DOM definitions in one spot.
  var _getDomNodes = function() {
    nodes.cartTotalContainer = $('.js-gnav-util__cart-count');
  };

  return {
    init: function() {
      _getDomNodes();
      cookie.set();
      events.init();
    },
    set: function() {
      cookie.set();
    },
    getValue: function(key) {
      return cookie.getValue(key);
    },
    setValue: function(key, val) {
      cookie.setValue(key, val);
    }
  };
}();

$(function () {
  // Set the cookie so other scripts can always access it in their $(document).ready:
  site.userInfoCookie.set();
})

})(jQuery, window.generic || {}, window.site || {});
